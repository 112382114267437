// Constants
import { USER_ROLES } from "../constants/General";

// Services
import StorageService from "../services/StorageService/index";

export const ispermssionsExist = (role, permissions) => {
  const res = permissions?.includes(role);
  return !!res;
};

export const authorizeUser = (role) => {
  return (
    role == USER_ROLES.INTERNAL_REVIEWER ||
    role == USER_ROLES.EXTERNAL_REVIEWER ||
    role == USER_ROLES.FEEDLOT_MANAGER ||
    role == USER_ROLES.SUPERVISOR ||
    role == USER_ROLES.WORKER
  );
};

export const isProgramManager = () => {
  const user_role_key = StorageService.instance.getUserInfo()?.user_role_key;
  return user_role_key === USER_ROLES.PROGRAM_MANAGER;
}

export const isTrainer = () => {
  const user_role_key = StorageService.instance.getUserInfo()?.user_role_key;
  return user_role_key === USER_ROLES.TRAINER;
}

export const isInternalAuditor = () => {
  const user_role_key = StorageService.instance.getUserInfo()?.user_role_key;
  return user_role_key === USER_ROLES.INTERNAL_AUDITOR;
}

export const isExternalAuditor = () => {
  const user_role_key = StorageService.instance.getUserInfo()?.user_role_key;
  return user_role_key === USER_ROLES.EXTERNAL_AUDITOR;
}

export const isAuditor = () => {
  return isInternalAuditor() || isExternalAuditor();
}

export const isNonAuditor = () => {
  return !isAuditor();
}

