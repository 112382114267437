import React from "react";

//offline
import { Detector } from "react-detect-offline";

//antd
import { Modal, Button } from "antd";

//images
import serverImg from "../../../assets/images/offline-img.svg";

//translation
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const Offline = (props) => {
  const { t } = useTranslation();

  //locat state
  const [online, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
    storeImage();
  }, []);

  const storeImage = () => {
    fetch(serverImg)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const image = reader.result;
          localStorage.setItem("offlineImage", image);
        };
      });
  };

  const renderOffline = () => {
    // alert("You need to have an active internet connection!");
    const data = localStorage.getItem("offlineImage");

    return (
      <div className="server-error">
        <img src={data} alt="" />
        <h3 className="txt-secondary mt-5">{t("noConnection")}</h3>
        <p>{t("offlineMessage")}</p>
        <Button type="primary" className="mx-4 mt-1">
          {t("retry")}
        </Button>

        {/* {!!refresh && (
          <Button type="primary" className="mx-4 mt-1" onClick={refresh}>
            {t("refresh")}
          </Button>
        )} */}
      </div>
    );
  };

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  return (
    // <Detector
    //   render={({online}) =>
    //     online ? (
    //       props?.children
    //     ) : (
    //       <div className={online ? "normal" : "warning"}>{renderOffline()}</div>
    //     )
    //   }
    // />
    !!online ? props?.children : renderOffline()
  );
};

export default Offline;
