/* 
  Main Layout for application.
  It contains header, footer, body, routes
*/

import React from "react";
import { useLocation } from "react-router-dom";

// Antd
import { Row, Col, Layout } from "antd";

// Components
import MenuArea from "./AppMenuArea";
import HeaderArea from "./AppHeaderArea";

// Constants
import { VERSION } from "../../config/version";

// Logo
import logo from "./../../assets/images/pb-logo.png";
import { Footer } from "antd/lib/layout/layout";

// Translation
import { useTranslation } from "react-i18next";

// Services
import StorageService from "../../services/StorageService/index";

const { Header, Content, Sider } = Layout;

function BaseLayout({ children }) {
  // Translation
  const { t } = useTranslation();

  let { pathname } = useLocation();
  const user = StorageService.instance.getUserInfo()?.user_role_name;

  const sideBar = () => {
    return (
      <Sider breakpoint="xl" className="sider">
        <Row>
          <Col className="sider-logo">
            <img src={logo} alt="logo" />
          </Col>
        </Row>
        <MenuArea pathname={pathname} role={user} onClickMenu={() => null} />
        <Footer align="center">
          <h5>
            {t("versionNumber")}: {VERSION}
          </h5>
          <small>
            {t("copyright")} © {new Date().getFullYear()}{" "}
            {t("progressiveBeefCopyright")}
          </small>
        </Footer>
      </Sider>
    );
  };

  return (
    <Layout className="main-layout">
      {sideBar()}
      <Content className={""}>
        <Layout className="">
          <Header>
            <HeaderArea />
          </Header>
        </Layout>

        <div className="content-section">{children}</div>
        {/* <Footer align="center">
          {t("copyright")} © {new Date().getFullYear()}{" "}
          {t("progressiveBeefCopyright")}
        </Footer> */}
      </Content>
    </Layout>
  );
}

export default BaseLayout;
