/* 
  Header area for application
*/

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Antd
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Space,
  Dropdown,
  Menu,
  Avatar,
  Typography,
  Modal,
  Form,
} from "antd";

// BreadCrumbs
import Breadcrumbs from "../AppBreadcrumbs";

// Component
import HeaderDrawer from "./HeaderDrawer";
import FeedyardRankButton from "./FeedyardRankButton";
import ComplianceCalculatorButton from "./ComplianceCalculatorButton";
import Loading from "../../Loading";

// Constant
import APP_URL from "../../../constants/AppRoutes";
import { REDUX_STATES } from "../../../constants/ReduxStates";

// Services
import StorageService from "../../../services/StorageService";

// Actions
import { logout } from "../../../store/actions/AuthAction";

// Helpers
import { isProgramManager } from "../../../helpers/Permissions";

// Translate
import { useTranslation } from "react-i18next";

// Style
import "./style.scss";

const { Title, Text, Paragraph } = Typography;
const { confirm } = Modal;
const {
  FEEDYARD_RANK_LIST,
  FOR_COMPLIANCE,
  ALL_FEEDYARDS,
  DOWNLOAD_EXCEL_REPORT,
  LOADING,
} = REDUX_STATES;

function AppHeaderArea() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useNavigate();

  const [form] = Form.useForm();

  // Local States
  const [drawer, setDrawer] = useState(null);

  // Redux States
  const { pageTitle } = useSelector((state) => state?.Breadcrumb);
  const {
    [FEEDYARD_RANK_LIST + LOADING]: feedyardRankloading = false,
    [ALL_FEEDYARDS + LOADING]: feedlotloading = false,
    [FOR_COMPLIANCE + LOADING]: complianceLoading = false,
    [DOWNLOAD_EXCEL_REPORT + LOADING]: reportLoading = false,
  } = useSelector((state) => state?.Crud);

  const {
    first_name = "",
    last_name = "",
    user_role_name = "",
  } = StorageService.instance.getUserInfo() || {};

  const onMenu = (e) => {
    switch (e?.key) {
      case "0":
        history(APP_URL.CHANGE_PASSWORD);
        break;
      case "1":
        onLogout();
        break;
      default:
        break;
    }
  };

  const onLogout = (e) => {
    confirm({
      title: t("confirm"),
      centered: "centered",
      icon: <ExclamationCircleOutlined />,
      content: t("sureLogout"),
      okText: t("yes"),
      cancelText: t("No"),
      onOk() {
        logoutSystem();
      },
    });
  };

  const logoutSystem = () => {
    dispatch(logout()).then(() => {
      history(APP_URL.LOGIN);
    });
  };

  const onCloseDrawer = () => {
    setDrawer(null);
    form.resetFields();
  };

  const menu = (
    <Menu
      onClick={onMenu}
      items={[
        {
          label: t("changePassword"),
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: t("logout"),
          key: "1",
        },
      ]}
    />
  );

  const getAbbrivation = () => {
    return first_name?.charAt(0) + last_name?.charAt(0) || "PB";
  };

  return (
    <>
      {(feedyardRankloading ||
        feedlotloading ||
        complianceLoading ||
        reportLoading) && <Loading />}
      <Row type="flex" align="middle">
        <Col xs={8} xl={12}>
          <Title level={3} title={pageTitle || ""} className="truncate-txt">
            {pageTitle || ""}
          </Title>
          <Breadcrumbs />
        </Col>
        <Col xs={16} xl={12} align="end">
          <Space size={16}>
            {isProgramManager() && <FeedyardRankButton setDrawer={setDrawer} />}
            {isProgramManager() && (
              <ComplianceCalculatorButton setDrawer={setDrawer} />
            )}

            <Dropdown overlay={menu} onClick={onMenu} className="user-dropdown">
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Avatar size={40}>{getAbbrivation()}</Avatar>
                  <Paragraph>
                    <Text className="dropdown-title">
                      {first_name + " " + last_name}
                    </Text>
                    <small>{user_role_name}</small>
                  </Paragraph>
                  <i className="icon-angle-down" />
                </Space>
              </a>
            </Dropdown>
          </Space>
        </Col>
      </Row>
      <HeaderDrawer drawer={drawer} form={form} onClose={onCloseDrawer} />
    </>
  );
}

export default AppHeaderArea;
