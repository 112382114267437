/* Left Menus List */

// Constants
import URL from "./AppRoutes";
import { PERMISSIONS } from "./Permissions";

const { DASHBOARD, FEEDYARDS, USERS, ACTIVITIES, VIDEOS, REPORTS, AUDITS,SOPS } =
  PERMISSIONS;

const MENUS = [
  {
    name: "Dashboard",
    icon: "icon-dashboard",
    url: URL.DASHBOARD,
    permissions: DASHBOARD,
    path: "dashboard",
  },
  {
    name: "Feedyards",
    icon: "icon-feedyard",
    url: URL.FEEDYARDS,
    permissions: FEEDYARDS,
    path: "feedyard",
  },
  {
    name: "Users",
    icon: "icon-users",
    url: URL.USERS,
    permissions: USERS,
    path: "user",
  },
  {
    name: "Activities",
    icon: "icon-activities",
    url: URL.ACTIVITIES,
    permissions: ACTIVITIES,
    path: "activities",
  },
  {
    name: "Videos",
    icon: "icon-videos",
    url: URL.VIDEOS,
    disabled: false,
    permissions: VIDEOS,
    path: "videos",
    subMenus: [
      {
        name: "App Navigation Videos",
        icon: "icon-an-video",
        url: URL?.APP_VIDEO,
        permissions: VIDEOS,
        path: "videos",
      },
      {
        name: "PB Training Videos",
        icon: "icon-pb-training",
        url: URL.PB_VIDEO,
        permissions: VIDEOS,
        path: "videos",
      },
    ],
  },
  {
    name: "Reports",
    icon: "icon-reports",
    permissions: REPORTS,
    path: "reports",
    url: URL.REPORTS,
    subMenus: [
      {
        name: "Audit Reports",
        icon: "icon-audit-report",
        url: URL.AUDIT_REPORTS,
        permissions: REPORTS,
        path: "reports",
      },
      {
        name: "Custom Reports",
        icon: "icon-comp-report",
        url: URL.CUSTOM_REPORTS,
        permissions: REPORTS,
        path: "reports",
      },
      {
        name: "Compliance Reports",
        icon: "icon-custom-report",
        url: URL.COMPLIANCE_REPORTS,
        permissions: REPORTS,
        path: "reports",
      },
    ],
  },
  {
    name: "Audits",
    icon: "icon-audits",
    url: URL.AUDITS,
    permissions: AUDITS,
    path: "audit",
  },
  {
    name: "SOP",
    icon: "icon-sop",
    url: URL.SOP,
    permissions: SOPS,
    path: "sop",
  },
];

export default MENUS;
