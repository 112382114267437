/* 
  Header Drawer
*/

import React, { useState } from "react";

// Redux
import { useSelector } from "react-redux";

// Antd
import { SearchOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";

//components
import NoDataFound from "../../../../Error/NoDataFound";

// Constants
import { REDUX_STATES } from "../../../../../constants/ReduxStates";

// Data Parser
import { parseFeedyardRankListingData } from "../../../../../dataParser/AppHeader";

// Translate
import { useTranslation } from "react-i18next";

const { FEEDYARD_RANK_LIST, VIEW } = REDUX_STATES;

const TABLE_COLUMNS = [
  {
    title: "Feedyard",
    dataIndex: "feedlot_name",
    key: "feedlot_name",
    sorter: (a, b) => a?.feedlot_name?.localeCompare(b?.feedlot_name),
  },
  {
    title: "Rank",
    dataIndex: "position",
    key: "position",
    sorter: (a, b) => a.position - b.position,
  },
  {
    title: "Performance",
    dataIndex: "performance",
    key: "performance",
    // sorter: (a, b) => a.performance - b.performance,
    render: (text) => <>{(text?.toFixed(2) || 0) + "%"}</>,
  },
];

function FeedyardRank() {
  const { t } = useTranslation();

  // Local States
  const [search, setSearch] = useState("");

  // Redux States
  const { [FEEDYARD_RANK_LIST + VIEW]: feedyardRankList = {} } = useSelector(
    (state) => state?.Crud
  );

  return (
    <>
      <div className="pb-4">
        <Input
          placeholder="Search Here"
          prefix={<SearchOutlined size={"large"} />}
          onChange={(e) => setSearch((e?.target?.value || "").trim())}
          allowClear
        />
      </div>
      <Table
        className="drawer-table h-82vh"
        dataSource={parseFeedyardRankListingData(feedyardRankList, search)}
        columns={TABLE_COLUMNS}
        pagination={false}
        locale={{
          emptyText: (
            <NoDataFound
              message={
                !!search?.length ? `${t("noFeedyardFound")} ' ${search}'` : ""
              }
            />
          ),
        }}
      />
    </>
  );
}

export default FeedyardRank;
