export const USER_ACTIONS = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  FORGET_PASSWORD_REQUEST: "FORGET_PASSWORD_REQUEST",
  FORGET_PASSWORD_SUCCESS: "FORGET_PASSWORD_SUCCESS",
  FORGET_PASSWORD_FAILURE: "FORGET_PASSWORD_FAILURE",

  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
};

export const BREADCRUMB_ACTIONS = {
  UPDATE_BREADCRUMBS: "UPDATE_BREADCRUMBS",
};

export const CRUD_ACTION = {
  ADD_REQUEST: "ADD_REQUEST",
  ADD_SUCCESS: "ADD_SUCCESS",
  ADD_FAILURE: "ADD_FAILURE",

  EDIT_REQUEST: "EDIT_REQUEST",
  EDIT_SUCCESS: "EDIT_SUCCESS",
  EDIT_FAILURE: "EDIT_FAILURE",

  VIEW_REQUEST: "VIEW_REQUEST",
  VIEW_SUCCESS: "VIEW_SUCCESS",
  VIEW_FAILURE: "VIEW_FAILURE",

  DELETE_REQUEST: "DELETE_REQUEST",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  DELETE_FAILURE: "DELETE_FAILURE",

  UPDATE_STATE: "UPDATE_STATE",
  UPDATE_COMPLIANCE_DATA: "UPDATE_COMPLIANCE_DATA"
};
