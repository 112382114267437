// Service
import service from "../../services/Api/Service";
import StorageService from "../../services/StorageService";

//file-saver
import { saveAs } from "file-saver";

// Constatns
import { USER_ACTIONS } from "../../constants/ActionKeys";
import { API_URLS } from "../../constants/ApiConstants";
import APP_URL  from "../../constants/AppRoutes";

// General
import { request, success, failure } from ".";
import { errorNotification, successNotification } from "../../helpers/Notification";

function login(loginModel) {
  return async (dispatch) => {
    try {
      dispatch(request(USER_ACTIONS.LOGIN_REQUEST));

      // API Calling
      const response = await service.postService(API_URLS.LOGIN, loginModel);

      // Save Access Token
      const data = response?.data?.response;
      if (data?.success) {
        // Access Token
        const accessToken = data?.PBBeans?.user?.token || null;
        StorageService.instance.setAccessToken(accessToken);

        // User Info
        const profile = data?.PBBeans?.user?.profile || null;
        const user_name = data?.PBBeans?.user?.user_name || "";
        const user_role_key = data?.PBBeans?.user?.user_role_key || "";
        const user_role_name = data?.PBBeans?.user?.user_role_name || "";

        StorageService.instance.setUserInfo({ ...profile, user_role_name, user_role_key, user_name });

        dispatch(success(USER_ACTIONS.LOGIN_SUCCESS, response));
      } else {
        dispatch(failure(USER_ACTIONS.LOGIN_FAILURE));
      }

      return data;
    } catch (error) {
      dispatch(failure(USER_ACTIONS.LOGIN_FAILURE));
      throw error;
    }
  };
}

function check_report_jobs(report_job) {
  //return {};
  return () => {
    try {
      // API Calling
      const report_job_id = report_job.report_job_id;
      var report_job_url = API_URLS.GET_REPORT_JOB;
      report_job_url = report_job_url.replace(":id", report_job_id);
      service.getService(report_job_url)
      .then( rs => {
        const data = rs.response;
        if (data?.success) {
          // Access Token
          const reportJob = data?.PBBeans?.report_job;
          if(reportJob){
            var status            = reportJob.status;
            var error_msg         = reportJob.error_message;
            var report_type       = reportJob.report_type;
            var file_name         = reportJob.file;
            var file_content_type = reportJob.file_content_type;
            var to_remove = false;
  
            if(status == 1){
              to_remove = true;
              successNotification("Report has been processed successfully.");
              // check type
              if(report_type == 1){
                // it will be downloaded
                setTimeout(()=> {
                  downloadPendingReport(reportJob)
                }, 5000);
              }
              // for dashboad notification
              if(window.location.href.includes(APP_URL.DASHBOARD)){
                // todo for load notification data
                setTimeout(()=> {
                  window.location.reload();
                }, 1000);
              }
            } else if(status == 0){
              // failed
              to_remove = true;
              error_msg = "Report job: " + error_msg;
              errorNotification(error_msg);
            } else {
              // still continue
              return;
            }
            if(to_remove){
              // remove from pending jobs list
              var pending_jobs  = StorageService.instance.getPendingJobs();
              const filteredData = pending_jobs.filter(item => item.report_job_id !== report_job_id);
              pending_jobs = filteredData;
              StorageService.instance.setPendingJobs(pending_jobs);
  
            }
          }
        }
      });
    } catch (error) {
      throw error;
    }
  };
}

function downloadPendingReport(report_job) {
  var report_job_url = API_URLS.DOWNLOAD_REPORT_FILE;
  var data = { report_job: { report_job_id: report_job.report_job_id } };

  service.postService(report_job_url, data, {responseType: 'arraybuffer'})
    .then((response) => {
      const { headers, data } = response || {};
      const filename = headers["filename"];
      const blob = new Blob([data], { type: 'application/octet-stream' });


      saveAs(blob, filename);
    })
    .catch((error) => {
      errorNotification("networkError");
    });
}


function refresh() {
  return async (dispatch) => {
    try {
      dispatch(request(USER_ACTIONS.REFRESH_TOKEN_REQUEST));

      // API Calling
      const response = await service.getService(API_URLS.REFRESH_TOKEN);

      dispatch(success(USER_ACTIONS.REFRESH_TOKEN_SUCCESS, response));
    } catch (error) {
      dispatch(failure(USER_ACTIONS.REFRESH_TOKEN_FAILURE));
      throw error;
    }
  };
}

function forgetUserPassword(data) {
  return async (dispatch) => {
    try {
      dispatch(request(USER_ACTIONS.FORGET_PASSWORD_REQUEST));

      // API Calling
      const response = await service.postService(
        API_URLS.FORGET_PASSWORD,
        data
      );

      dispatch(success(USER_ACTIONS.FORGET_PASSWORD_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(failure(USER_ACTIONS.FORGET_PASSWORD_FAILURE));
      throw error;
    }
  };
}

function logout() {
  return async (dispatch) => {
    try {
      dispatch(request(USER_ACTIONS.LOGOUT_REQUEST));
      await StorageService.instance.deleteLoginData();
      dispatch(success(USER_ACTIONS.LOGOUT_SUCCESS));
    } catch (error) {
      dispatch(failure(USER_ACTIONS.LOGOUT_FAILURE, error));
      throw error;
    }
  };
}

export function resetPassword(data, opt) {
  return async (dispatch) => {
    try {
      dispatch(request(USER_ACTIONS.RESET_PASSWORD_REQUEST));

      // API Calling
      const response = await service.postService(
        API_URLS.RESET_PASSWORD,
        data,
        opt
      );

      dispatch(success(USER_ACTIONS.RESET_PASSWORD_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(failure(USER_ACTIONS.RESET_PASSWORD_FAILURE));
      throw error;
    }
  };
}

export { login, refresh, logout, forgetUserPassword , check_report_jobs};
