import React from 'react';

// Antd
import { Empty } from 'antd';

// Translate
import { useTranslation } from 'react-i18next';

const NoData = ({
  message
}) => {
  const { t } = useTranslation();

  return (
    <Empty 
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"

      description={
        <span>
          { message || t('noDataFound') }
        </span>
      }
    />
  );
};

export default NoData;