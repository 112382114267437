/*
  Input Text
*/

import React from "react";

// Antd
import { Input, Form } from "antd";

// Translate
import { useTranslation } from "react-i18next";

//import { UserOutlined } from '@ant-design/icons';

const UserIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 896 1024"
  >
    <path
      fill="#fff"
      d="M544 608h-192c-196 0-352 158.003-352 352 0 36 28 64 64 64h768c33.997 0 64-28 64-64 0-193.997-158.003-352-352-352zM96 928c16-126.003 124-224 256-224h192c129.997 0 238.003 97.997 254.003 224h-702.003zM448 512c140 0 256-114 256-256 0-140-116-256-256-256-142 0-256 116-256 256 0 142 114 256 256 256zM448 96c88 0 160 72 160 160 0 90-72 160-160 160-90 0-160-70-160-160 0-88 70-160 160-160z"
    ></path>
  </svg>
);

// Style
import "./style.scss";

function Text({
  name,
  label,
  placeholder,
  readOnly,
  defaultValue,
  onChange,
  disabled,
  required,
  onBlur,
  validator,
  pattern,
  validateTrigger,
  maxLength,
  notwrapInForm,
  message,
  patternMessage,
  autoComplete,
  ...rest
}) {
  const { t } = useTranslation();

  // Rules
  let rules = [
    { required: required, message: message ? message : t("Required") },
    { pattern: pattern, message: patternMessage },
    ...(validator || []),
  ];

  // Input Text
  const InputText = (
    <Input
      { ...rest }
      placeholder={placeholder || label}
      onChange={onChange || null}
      defaultValue={defaultValue}
      disabled={disabled}
      onBlur={onBlur}
      maxLength={maxLength}
      readOnly={readOnly}
      autoComplete={autoComplete || "off"}
    />
  );

  return !!notwrapInForm ? (
    <>{InputText}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
    >
      {InputText}
    </Form.Item>
  );
}

export default Text;
