export const DATE_FORMAT = {
  YEAR_MONTH_DAY_WITHOUT_SEPARATOR: "yyMMdd",
  MONTH_SLASH_DAY_SLASH_YEAR: "MM/dd/yyyy hh:mm:ss",
  MONTH_DAY_YEAR: "MM/dd/yyyy",
  YEAR_MONTH_DAY_HH_MM: "yyyy-MM-dd | hh:mm a",
  YEAR_MONTH_DAY: "yyyy-MM-dd",
  SHORTMONTH_DATE: "MMM dd",
  SHORTMONTH_DATE_YEAR: "MMM dd, yyyy",
  SHORTMONTH_DATE_YEAR_HOUR_MIN: "MMM dd, yyyy HH:mm",
  MONTH_SLASH_DAY_SLASH_YEAR_HOUR_MIN: "MM/dd/yyyy | hh:mm a",
  DATE_TIME_FORMAT: "yyyy-MM-dd hh:mm:ss",

  DATE_FORMAT: "yyyy-MM-dd",

  MONTH_DATE: "MMMM dd",
  MONTH_DATE_YEAR: "MMMM dd, yyyy",
  DAYNAME_SHORTMONTH_DATE_YEAR: "EEEE MMM dd, yyyy",
  ONLY_DAY: "EEEE",
  WEEKNO_SHORTDAY_MONTH_SLASH_DAY_SLASH_YEAR: "I - E - MM/dd/yyyy",
  WEEK_NO: "I",
  MONTH_SLASH_DAY: "MM/dd",
  HOUR_MINUTE_12F: "hh:mm a",
  HOUR_MINUTE_SECONDS: "HH:mm:ss",

  YEAR_MONTH_DAY_T_FORMAT: "yyyy-MM-dd'T'HH:mm:ss.SSS",

  MONTH_SLASH_YEAR: "MM/yyyy",
};
