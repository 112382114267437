/*
  Main File, 1st file to execute in project
*/

import React from "react";
import ReactDOM from "react-dom/client";
import BootSetup from "./BootSetup";
import "./i18n";
import * as serviceWorker from "./serviceWorker";
// import 'antd/dist/antd.css'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <BootSetup />
  // </React.StrictMode>
  <>
    <BootSetup />
  </>
);

// serviceWorker.unregister();
serviceWorker.register();