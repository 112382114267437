import React, {useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import AppRouter from "./router/index";
import APP_URL from "./constants/AppRoutes";

// Redux
import {useDispatch} from "react-redux";

// Actions
import {refresh, check_report_jobs} from "./store/actions/AuthAction";

//components
import Offline from "./components/Common/Offline";
import { useState } from "react";
import { successNotification } from "./helpers/Notification";
import StorageService from "./services/StorageService";

//images
// import serverImg from "../../../assets/images/empty-img.svg";

function App() {
  const dispatch = useDispatch();

  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    function refreshToken() {
      !window.location.href.includes(APP_URL.LOGIN) &&
        !window.location.href.includes(APP_URL.RESET_PASSWORD) &&
        dispatch(refresh());
    }

    function check_report_status (){
      var pending_jobs  = StorageService.instance.getPendingJobs();
      if(pending_jobs.length > 0){
        dispatch(check_report_jobs(pending_jobs[0]));
      }
    }
    refreshToken();
    check_report_status();
    const interval = setInterval(() => refreshToken(), 300000);
    const interval2 = setInterval(() => check_report_status(), 20000);
    return () => {
      clearInterval(interval);
      clearInterval(interval2);
    };
  }, []);

  return (
    <Router>
      <Offline>
        <AppRouter />
      </Offline>
    </Router>
  );
}

export default App;
