/*
  Multi Select Dropdown
*/

import React, { useEffect } from "react";

// Antd
import { Select, Form, Button } from "antd";

// Translate
import { useTranslation } from "react-i18next";

const { Option } = Select;

function MultiSelect({
  name,
  label,
  placeholder,
  options,
  onChange,
  defaultValue,
  allowClear,
  disabled,
  required,
  loading,
  onKeyUp,
  onKeyDown,
  validator,
  validateTrigger,
  notwrapInForm,
  tagCount,
  message,
  showSelectAll,
  form,
  className,
  resetSelectedItems,
  setResetButtonClicked,
  setSelectAllButtonClicked
}) {

  useEffect(()=>{
    if(resetSelectedItems){
      handleUnSelectAll();
      // after handling resetSelectedItems state to false,
      // to handle the case for another attempt!
      setResetButtonClicked(false);
    }
  },[resetSelectedItems])
  const { t } = useTranslation();
  // const sortBy = [{ prop:'text', direction: 1 }];
  // const sortedOption = sortArray(options, sortBy);
  const sortedOption = options;

  // Validations rules
  let rules = [
    { required: required, message: message || "Required" },
    ...(validator || []),
  ];

  // if (!!validator) {
  //   rules.push(validator || []);
  // }

  const handleSelectAll = () => {
    if(setSelectAllButtonClicked){
      setSelectAllButtonClicked(true);
    }
    form.setFieldValue(
      name,
      sortedOption.map((el) => el.value)
    );
    onChange?.();
  };

  const handleUnSelectAll = () => {
    form.setFieldValue(name, []);
    onChange?.();
  };

  const DropdownList = (
    <Select
      className={`${!className ? 'custom-multi-select' : className}`}
      getPopupContainer={(trigger) => trigger.parentNode}
      mode="multiple"
      // mode="tags"
      // showArrow
      showArrow
      showSearch={true}
      suffixIcon={<i className="icon-caret-down"></i>}
      // showSearch={ showSearch }
      allowClear={allowClear}
      defaultValue={defaultValue}
      placeholder={placeholder || "Please Select"}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      disabled={disabled}
      loading={loading}
      optionFilterProp="title"
      maxTagCount={tagCount ? tagCount : "responsive"}
      // notFoundContent={< NoContent loading={ loading } />}
    >
      {!!showSelectAll && !!form && (
        <Option
          key=""
          value=""
          disabled={true}
          className="activities-select-align"
        >
          <Button type="text" onClick={handleSelectAll}>
            {t("selectAll")}
          </Button>
          <Button type="text" onClick={handleUnSelectAll}>
            {t("reset")}
          </Button>
        </Option>
      )}

      {sortedOption &&
        sortedOption.map((data, index) => {
          return (
            <Option key={index} title={!!data?.title ? data.title : data?.value} value={data.value}>
              {data.name}
            </Option>
          );
        })}
    </Select>
  );

  return !!notwrapInForm ? (
    <>{DropdownList}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
    >
      {DropdownList}
    </Form.Item>
  );
}

export default MultiSelect;
