import { BREADCRUMB_ACTIONS} from '../../constants/ActionKeys';

let initialState = {
  data: [],
  pageTitle: ""
};

const Breadcrumb = (state = initialState, action) => {
  switch (action.type) {

  // Login
  case BREADCRUMB_ACTIONS.UPDATE_BREADCRUMBS:
    const arr = action?.payload?.breadcrumbs || [];
    const title = action?.payload?.title || "";

    return { 
      ...state,
      data: arr,
      pageTitle: title
    };

  default:
    return state;
  }
};

export default Breadcrumb;