/* 
  Header Drawer
*/

import React from "react";

// Redux
import { useDispatch } from "react-redux";

// Antd
import { Button } from "antd";

// Constants
import { API_URLS } from "../../../../constants/ApiConstants";
import { REDUX_STATES } from "../../../../constants/ReduxStates";

// Helpers;
import { errorNotification } from "../../../../helpers/Notification";

// Actions;
import { getList } from "../../../../store/actions/CRUDAction";

// Translate
import { useTranslation } from "react-i18next";

const { ALL_FEEDYARDS } = REDUX_STATES;

function ComplianceCalculatorButton({ setDrawer }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onComplianceCalculator = () => {
    dispatch(getList(API_URLS.FEEDYARD_LIST, {}, ALL_FEEDYARDS)).then(
      () => {
        setDrawer(2);
      },
      () => {
        errorNotification(t("errorFeedyardRankList"));
      }
    );
  };

  return (
    <Button type="secondary" onClick={onComplianceCalculator}>
      {t("complianceCalculator")}
    </Button>
  );
}

export default ComplianceCalculatorButton;
