/*
  General Response Handler
*/

import StorageService from '../../services/StorageService';

const text1 = 'expired token';
const text2 = 'unable to authenticate';
const text3 = 'session has expired';

export function handleResponse(response) {
  
  if (response && response.status === 200) {
    const message = (response?.data?.response?.message || "").toLowerCase();

    if(message?.includes(text1) || message?.includes(text2) || message?.includes(text3)){
      StorageService.instance.deleteLoginData(); // clear session
      window.location.reload();
    }
    return response.data;
  } else {
    handleError(response);
  }
}

export function handleError(error) {
  if(error?.response?.status === 401){
    StorageService.instance.deleteLoginData(); // clear session
    window.location.reload();
  }
  throw error;
}