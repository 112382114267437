import React from "react";

// Antd
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Loading = () => {
  const antIcon = <LoadingOutlined className="font-24" spin />;

  return (
    <div className="loading">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loading;
