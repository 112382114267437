/*
  Date Picker
*/

import React from "react";

// Antd
import { Form } from "antd";

import DatePicker from "./DatePickerComponent";

// import { CaretDownOutlined } from '@ant-design/icons';

// import { SPACE_VALIDATIOR } from '../../../../constants/Patterns';

// Style
// import './style.scss';s

// Translate
import { useTranslation } from "react-i18next";

function Picker({
  name,
  label,
  placeholder,
  readOnly,
  defaultValue,
  format,
  onChange,
  disabled,
  required,
  onBlur,
  validator,
  validateTrigger,
  disabledDate,
  notwrapInForm,
  type,
  showTime,
  message,
}) {
  // Rules
  const { t } = useTranslation();
  let rules = [
    { required: required, message: message ? message : t("Required") },
    // {
    //   pattern: SPACE_VALIDATIOR,
    //   message: LOCALIZATION.SPACE_VALIDATION
    // }

    ...(validator || []),
  ];

  // Custom Validations
  // !!validator && rules.push({ validator });

  const DatePickerInput = (
    // <Space direction="vertical" style={{ width: '100%' }}>
    <DatePicker
      placeholder={placeholder || label}
      onChange={onChange || null}
      defaultValue={defaultValue}
      disabled={disabled}
      onBlur={onBlur}
      picker={type}
      readOnly={readOnly}
      format={format}
      disabledDate={disabledDate}
      className="w-100"
      showTime={!!showTime && { use12Hours: true }}
      // suffixIcon={<CaretDownOutlined />}
    />
    // </Space>
  );

  return !!notwrapInForm ? (
    <>{DatePickerInput}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
    >
      {DatePickerInput}
    </Form.Item>
  );
}

export default Picker;
