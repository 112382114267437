const URL = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  FEEDYARDS: "/feedyards",
  ADD_FEEDYARD: "/feedyard/add",
  UPDATE_FEEDYARD: "/feedyard/:id/edit",
  FEEDYARDS: "/feedyard/list",
  PROGRAM_MANUAL: "/program-manual/list",
  USERS: "/user/list",
  ACTIVITIES: "/activities/list",
  VIDEOS: "/videos",
  REPORTS: "/reports",
  COMPLIANCE_REPORTS: "/reports/compliance-report",
  CUSTOM_REPORTS: "/reports/custom-report",
  AUDIT_REPORTS: "/reports/audit-report",
  AUDITS: "/audits",
  ADD_DOCUMENT: "/add",
  ADD_ACTIVITY: "/activities/add",
  VIEW_ACTIVITY: "/activities/:id",
  UPDATE_ACTIVITY: "/activites/:id/edit",
  ADD_USER: "/user/add",
  EDIT_USER: "/user/:id/edit",
  ADD_BULK_USER: "/user/bulk",
  ACTIVITIES_AND_USERS: "/feedyard/:id/activities",
  VIEW_FEEDYARD_ACTIVITY:
    "/feedyard/feedlotactivity/:id/activity/:number",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/change-password",
  UPDATE_FEEDYARD_ACTIVITY:
    "/feedyard/feedlotactivity/:id/activity/:number/update",
  VIEW_FEEDYARD_SUBMISSION:
    "/feedyard/:id/activity/:number/submission-view/:feedlot_activity_id",
  VIEW_AUDIT: "/audit/view/:id",
  VIEW_AUDIT_SECTION: "/audit/view/:number/:id",
  PB_VIDEO: "/videos/pb-video",
  APP_VIDEO: "/videos/app-video",
  SOP: "/sop",
};

export default URL;
