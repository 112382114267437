/* Left Menus List */

// Constants
export const MODULE_PERMISSIONS = {
  PROGRAM_MANAGER: "Program Manager",
  TRAINER: "Trainer",
  INTERNAL_AUDITOR: "Internal Auditor",
  EXTERNAL_AUDITOR: "External Auditor",
};

export const PERMISSIONS = {
  DASHBOARD: [
    MODULE_PERMISSIONS.PROGRAM_MANAGER,
    MODULE_PERMISSIONS.EXTERNAL_AUDITOR,
    MODULE_PERMISSIONS.INTERNAL_AUDITOR,
    MODULE_PERMISSIONS.TRAINER,
  ],
  FEEDYARDS: [
    MODULE_PERMISSIONS.PROGRAM_MANAGER,
    MODULE_PERMISSIONS.EXTERNAL_AUDITOR,
    MODULE_PERMISSIONS.INTERNAL_AUDITOR,
    MODULE_PERMISSIONS.TRAINER,
  ],
  USERS: [MODULE_PERMISSIONS.PROGRAM_MANAGER],
  ACTIVITIES: [MODULE_PERMISSIONS.PROGRAM_MANAGER],
  VIDEOS: [MODULE_PERMISSIONS.PROGRAM_MANAGER],
  REPORTS: [MODULE_PERMISSIONS.PROGRAM_MANAGER],
  AUDITS: [MODULE_PERMISSIONS.PROGRAM_MANAGER],
  CHANGE_PASSWORD: [
    MODULE_PERMISSIONS.PROGRAM_MANAGER,
    MODULE_PERMISSIONS.EXTERNAL_AUDITOR,
    MODULE_PERMISSIONS.INTERNAL_AUDITOR,
    MODULE_PERMISSIONS.TRAINER,
  ],
  SOPS: [MODULE_PERMISSIONS.PROGRAM_MANAGER],
};