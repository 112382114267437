/*
    Notifications Helper
*/

import React from "react";

// Antd
import { notification, Modal } from "antd";

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

// Constants
import { NOTIFICATION_DURATION } from "../constants/General";
import STATUS_CODES from "../constants/StatusCodes";

export function showMessage(message, code, duration = NOTIFICATION_DURATION) {
  if (code === STATUS_CODES.SUCCESS) {
    success(message, duration);
  } else {
    error(message, duration);
  }
}

export function showWarning(message, callNextScreen) {
  Modal.warning({
    title: "LOCALIZATION.WARNING",
    content: (
      <div>
        {message?.warnings?.map((val) => {
          return <p>{val}</p>;
        })}
      </div>
    ),
    onOk() {
      callNextScreen(message);
    },
  });
}

// Success
export function successNotification(message, duration = NOTIFICATION_DURATION) {
  const key = "success";
  notification.open({
    //closeIcon: <i className="icon-highlight_remove"></i>,
    key,
    duration,
    icon: (
      <CheckCircleOutlined
        color="white"
        onClick={() => notification.close(key)}
      />
    ),
    description:
      typeof message === "string" ? (
        message || "Notification"
      ) : (
        <div>
          {message?.map((val) => {
            return <p>{val}</p>;
          })}
        </div>
      ),
    className: "notification-success",
  });
}

// Error
export function errorNotification(message, duration = NOTIFICATION_DURATION) {
  const key = "error";
  notification.open({
    //closeIcon: <i className="icon-highlight_remove"></i>,
    key,
    duration,
    icon: (
      <CloseCircleOutlined
        color="white"
        onClick={() => notification.close(key)}
      />
    ),
    description:
      typeof message === "string" ? (
        message || "Notification"
      ) : typeof message === "object" ? (
        <div style={{ maxHeight: '100px',overflowY: "scroll" }}>
          { message || "Notification"}
        </div>
      ) : (
        <div>
          {message?.map((val) => {
            return <p>{val}</p>;
          })}
        </div>
      ),
    className: "notification-error",
  });
}

// Warning
export function warningNotification(message, duration = NOTIFICATION_DURATION) {
  const key = "warning";
  notification.open({
    //closeIcon: <i className="icon-highlight_remove"></i>,
    key,
    duration,
    icon: (
      <WarningOutlined color="white" onClick={() => notification.close(key)} />
    ),
    description: message || "Notification",
    className: "notification-warning",
  });
}

// Info
export function infoNotification(message, duration = NOTIFICATION_DURATION) {
  const key = "info";
  notification.open({
    //closeIcon: <i className="icon-highlight_remove"></i>,
    key,
    duration,
    icon: (
      <InfoCircleOutlined
        color="white"
        onClick={() => notification.close(key)}
      />
    ),
    description: message || "Notification",
    className: "notification-info",
  });
}
