import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// Components
import BaseLayout from "../BaseLayout";

// Constants
import APP_ROUTES from "../../constants/AppRoutes";

// Services
import StorageService from '../..//services/StorageService';

const PrivateRoute = () => {
    const auth = StorageService.instance.getAccessToken(); // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? 
        <BaseLayout>
            <Outlet /> 
        </BaseLayout>: 
        <Navigate to={ APP_ROUTES.LOGIN } />;
}

export default PrivateRoute;
