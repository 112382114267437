/* eslint-disable import/no-anonymous-default-export */
import Auth from './AuthReducer';
import Breadcrumb from './BreadcrumbReducer';
import Crud from './CRUDReducer';

export default {
  Auth,
  Breadcrumb,
  Crud
};
