/* 
  Menus for application
*/

import React from "react";

import { NavLink } from "react-router-dom";

import { Menu } from "antd";

// Constants
import MENUS from "../../../constants/AppMenus";
import { ispermssionsExist } from "../../../helpers/Permissions";

// Style
import "./style.scss";

// Helpers
function AppMenuArea({ pathname, onClickMenu, role }) {

  const [openKeys, setOpenKeys] = React.useState([]);

  const setSelectedKey = (path) => {
    const obj = MENUS?.find((menu) => !!path?.includes(menu?.path));
    return !!obj && [obj?.url];
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys([latestOpenKey]);
  };
  const getSubMenus = (subMenus) => {
    return subMenus?.map((menu) => {
      const { name, icon, url } = menu;

      return (
        <Menu.Item
          key={"" + url}
          icon={<i className={icon}></i>}
          className="sub-menu-options"
        >
          <NavLink to={url || ""}>{name}</NavLink>
        </Menu.Item>
      );
    });
  };

  return (
    <Menu
      className="left-nav"
      mode="vertical"
      openKeys={openKeys}
      selectedKeys={setSelectedKey(pathname)}
      onOpenChange={onOpenChange}
    >
      {MENUS &&
        MENUS.map((data, index) => {
          const {
            name,
            icon,
            url,
            disabled,
            permissions,
            subMenus,
            path,
            parent,
          } = data;

          return (
            ispermssionsExist(role, permissions) &&
            (!subMenus?.length ? (
              <Menu.Item
                key={"" + url}
                // className={
                //   !!pathname?.includes(parent) ? "ant-menu-item-selected" : ""
                // }
                icon={<i className={icon}></i>}
                disabled={!!disabled}
                onClick={onClickMenu}
              >
                <NavLink to={url || ""}>{name}</NavLink>
              </Menu.Item>
            ) : (
              // <Menu.Item key={"" + url}>
              <Menu.SubMenu
                key={"" + url}
                icon={<i className={icon}></i>}
                onClick={onClickMenu}
                className={
                  !!pathname?.includes(path) ? "ant-menu-item ant-menu-item-selected px-0" : "ant-menu-item px-0"
                }
                title={name}
              >
                {getSubMenus(subMenus)}
              </Menu.SubMenu>
              // </Menu.Item>
            ))
          );
        })}
    </Menu>
  );
}

export default AppMenuArea;
