import React from "react";

// Translate
import { default as i, t } from "i18next";

//Date format
import { DATE_FORMAT } from "../constants/DateFormat";

//date-fns
import { format } from "date-fns";

//antd
import { Tooltip, Tag } from "antd";

// Helpers
import { NumberToAlphabet } from "../helpers/General";

// enums
import {
  ActivityEnums,
  SubmissionStatus,
  FieldTypeKeys,
  FieldTypes,
  RequiredFrequencyEnums,
  RULES,
} from "../Enums/ActivityEnums";

// Lodash
import _ from "lodash";

export const parseTrainerKeysData = (data) => {
  let arr = [];
  let response = data?.response?.PBBeans?.users || [];

  !!response?.length &&
    response.forEach((e, index) => {
      arr.push({
        id: e?.user_id,
        key: e?.user_id,
        value:
          (e?.profile?.first_name || "") +
          " " +
          (e?.profile?.last_name || "") +
          " (" +
          e?.user_name +
          ")",
      });
    });
  return arr;
};

export const parseStateKeysData = (data) => {
  let arr = [];
  let response = data?.response?.PBBeans?.menu_items || [];
  !!response?.length &&
    response.forEach((e, index) => {
      arr.push({
        id: index,
        key: e?.key,
        value: e?.value,
      });
    });
  return arr;
};

export const parseDefaultKeysData = (data) => {
  let arr = [];
  let response = data?.response?.PBBeans?.activities || [];
  !!response?.length &&
    response.forEach((e, index) => {
      arr.push({
        id: e?.activity_id,
        key: e?.activity_number,
        value: e?.activity_number,
      });
    });
  return arr;
};

export const parseFeedlotKeysData = (data) => {
  let arr = [];
  let response = data?.response?.PBBeans?.feedlot_managers || [];
  !!response?.length &&
    response.forEach((e, index) => {
      arr.push({
        id: e?.user_id,
        key: e?.user_id,
        value:
          (e?.profile?.first_name || "") +
          " " +
          (e?.profile?.last_name || "") +
          " (" +
          e?.user_name +
          ")",
      });
    });
  return arr;
};

export const parseAuditorKeysData = (data, role_id) => {
  let arr = [];
  let response = data?.response?.PBBeans?.auditors || [];
  !!response?.length &&
    response.forEach((e, index) => {
      if (e?.user_role_key === role_id) {
        arr.push({
          id: e?.user_id,
          key: e?.user_id,
          value:
            (e?.profile?.first_name || "") +
            " " +
            (e?.profile?.last_name || "") +
            " - " +
            e?.user_name,
        });
      }
    });
  return arr;
};

export const parseReviewKeysData = (data) => {
  let arr = [];
  let response = data?.response?.PBBeans?.reviewers || [];

  !!response?.length &&
    response.forEach((e, index) => {
      arr.push({
        id: e?.user_id,
        key: e?.user_id,
        roleKey: e?.user_role_key,
        value:
          (e?.profile?.first_name || "") +
          " " +
          (e?.profile?.last_name || "") +
          " ( " +
          e?.user_name +
          " )",
      });
    });
  return arr;
};

export const parseFeedyardEditData = (data) => {
  let response = data?.response?.PBBeans?.feedlot || [];
  return response;
};

export const parseFeedyardEditDropDownDataData = (data) => {
  let trainer = data.trainers || [];
  let feedmanager = data.feedlot_managers || [];
  let auditor = data.auditors || [];
  let reviewers = data.reviewers || [];
  let trainerdropdown = [];
  for (let i = 0; i < trainer.length; i++) {
    let obj = {
      key: trainer[i].user_id,
      value: `${trainer[i].profile?.first_name} ${trainer[i].profile?.last_name} (${trainer[i].user_name})`,
    };
    trainerdropdown.push(obj);
  }

  let feedlotmanagerdropdown = [];
  for (let j = 0; j < feedmanager.length; j++) {
    let obj = {
      key: feedmanager[j].user_id,
      value: `${feedmanager[j].profile?.first_name} ${feedmanager[j].profile?.last_name} (${feedmanager[j].user_name})`,
    };
    feedlotmanagerdropdown.push(obj);
  }

  let internalAuditor;
  let externalAuditor;
  for (let k = 0; k < auditor.length; k++) {
    if (auditor[k].user_role_key === 6) {
      internalAuditor = auditor[k].user_id;
    } else {
      externalAuditor = auditor[k].user_id;
    }
  }
  let internalReviewer;
  let externalReviewer;
  for (let m = 0; m < reviewers.length; m++) {
    if (reviewers[m].user_role_key === 8)
      internalReviewer = reviewers[m].user_id;
    if (reviewers[m].user_role_key === 9)
      externalReviewer = reviewers[m].user_id;
  }

  return [
    trainerdropdown,
    feedlotmanagerdropdown,
    internalAuditor,
    externalAuditor,
    internalReviewer,
    externalReviewer,
  ];
};

export const parsePostAddFeedyardData = (
  data,
  reviewer,
  trainer,
  feedlotmanager,
  defaultActivity,
  auditor
) => {
  let trainerData = trainer?.response?.PBBeans?.users || [];
  let response = reviewer?.response?.PBBeans?.reviewers || [];
  let feedmanagerData = feedlotmanager.response.PBBeans.feedlot_managers || [];
  let default_activity_data =
    defaultActivity?.response?.PBBeans.activities || [];
  let auditor_data = auditor?.response?.PBBeans?.auditors || [];

  let revs = [];
  for (let i = 0; i < reviewer?.response?.PBBeans?.reviewers.length; i++) {
    if (
      response[i].user_id === data.internal_reviewer ||
      response[i].user_id === data.external_reviewer
    ) {
      revs.push({
        user_id: response[i].user_id,
        user_role_key: response[i].user_role_key,
      });
    }
  }

  let arr = [];
  for (let j = 0; j < data.trainers?.length; j++) {
    for (let k = 0; k < trainerData?.length; k++) {
      if (
        `${trainerData[k].profile?.first_name} ${trainerData[k].profile?.last_name} (${trainerData[k].user_name})` ==
        data.trainers[j]
      ) {
        arr.push(trainerData[k]);
      }
    }
  }

  let arr2 = [];
  for (let j = 0; j < data.feedlot_managers?.length; j++) {
    for (let k = 0; k < feedmanagerData?.length; k++) {
      if (
        `${feedmanagerData[k].profile?.first_name} ${feedmanagerData[k].profile?.last_name} (${feedmanagerData[k].user_name})` ==
        data.feedlot_managers[j]
      ) {
        arr2.push(feedmanagerData[k]);
      }
    }
  }

  let arr3 = [];
  for (let j = 0; j < data?.default_activities?.length; j++) {
    for (let k = 0; k < default_activity_data?.length; k++) {
      if (
        default_activity_data[k].activity_number == data.default_activities[j]
      ) {
        arr3.push({
          activity_id: default_activity_data[k].activity_id,
          activity_number: default_activity_data[k].activity_number,
        });
      }
    }
  }

  let arr4 = [];
  for (let i = 0; i < auditor_data.length; i++) {
    if (
      auditor_data[i].user_id == data.internal_auditor ||
      auditor_data[i].user_id == data.external_auditor
    ) {
      arr4.push({
        user_id: auditor_data[i].user_id,
        user_role_key: auditor_data[i].user_role_key,
      });
    }
  }

  let payload = {
    feedlot: {
      status: data?.status,
      address: data?.address,
      state_key: data?.state_key,
      zip_code: data?.zip_code,
      backdate_prevention_enabled: 1,
      default_activities: data?.default_activities,
      name: data?.name,
      narc: data?.narc,
      phone_number: data?.phone_number,
      reviewers: revs,
      reviewer: {},
      trainers: arr,
      feedlot_managers: arr2,
      default_activities_temp: arr3,
      auditors: arr4,
    },
  };
  return payload;
};

export const parseEditFeedyardData = (
  data,
  reviewer,
  trainer,
  feedlotmanager,
  defaultActivity,
  auditor,
  editFeedyardData,
  backDateCheck
) => {
  let trainerData = trainer?.response?.PBBeans?.users || [];
  let response = reviewer?.response?.PBBeans?.reviewers || [];
  let feedmanagerData = feedlotmanager.response.PBBeans.feedlot_managers || [];
  let default_activity_data =
    defaultActivity?.response?.PBBeans.activities || [];
  let auditor_data = auditor?.response?.PBBeans?.auditors || [];
  let obj = {};
  let reviewers = [];
  for (let i = 0; i < response?.length; i++) {
    if (
      response[i].user_id === data.internal_reviewer ||
      response[i].user_id === data.external_reviewer
    ) {
      reviewers.push({
        user_id: response[i].user_id,
        user_role_key: response[i].user_role_key,
      });
    }
  }

  let trainer_arr = [];
  if (!data.trainers[0]?.key) {
    for (let j = 0; j < data.trainers?.length; j++) {
      for (let k = 0; k < trainerData?.length; k++) {
        if (
          `${trainerData[k].profile?.first_name} ${trainerData[k].profile?.last_name} (${trainerData[k].user_name})` ==
          data.trainers[j]
        ) {
          trainer_arr.push(trainerData[k]);
        }
      }
    }
  }
  let feedlot_managers = [];

  if (!data?.feedlot_managers[0]?.key) {
    for (let j = 0; j < data?.feedlot_managers?.length; j++) {
      for (let k = 0; k < feedmanagerData?.length; k++) {
        if (
          `${feedmanagerData[k].profile?.first_name} ${feedmanagerData[k].profile?.last_name} (${feedmanagerData[k].user_name})` ==
          data.feedlot_managers[j]
        ) {
          feedlot_managers.push(feedmanagerData[k]);
        }
      }
    }
  }

  let auditors = [];
  auditor = {};
  for (let i = 0; i < auditor_data.length; i++) {
    if (
      auditor_data[i].user_id == data.internal_auditor ||
      auditor_data[i].user_id == data.external_auditor
    ) {
      if (auditor_data[i].user_id == data.internal_auditor) {
        auditor = auditor_data[i];
      }
      auditors.push({
        user_id: auditor_data[i].user_id,
        user_role_key: auditor_data[i].user_role_key,
      });
    }
  }

  let payload = {
    feedlot: {
      notifications: editFeedyardData?.notifications,
      feedlot_id: editFeedyardData?.feedlot_id,
      name: data?.name,
      address: data?.address,
      state_key: data?.state_key,
      state_name: editFeedyardData?.state_name,
      state_initials: editFeedyardData?.state_initials,
      zip_code: data?.zip_code,
      phone_number: data?.phone_number,
      backdate_prevention_enabled: backDateCheck ? 1 : 0,
      needs_attention: editFeedyardData?.needs_attention,
      is_ranked: editFeedyardData?.is_ranked,
      training_rules_enabled: editFeedyardData?.training_rules_enabled,
      narc: data?.narc,
      status: data?.status,
      reviewers: reviewers,
      reviewer: {
        user_id: obj?.user_id,
        user_role_key: obj?.user_role_key,
      },
      auditors: auditors,
      auditor: auditor,
      feedlot_managers: data?.feedlot_managers[0]?.key
        ? editFeedyardData?.feedlot_managers
        : feedlot_managers,
      feedlot_manager: editFeedyardData?.feedlot_manager,
      trainer: data.trainers[0]?.key
        ? editFeedyardData?.trainers[0]
        : trainer_arr[0],
      trainers: data.trainers[0]?.key
        ? editFeedyardData?.trainers
        : trainer_arr,
    },
  };

  if (!backDateCheck) {
    let backdate_info = {
      backdate:
        format(data?.backdate, DATE_FORMAT.YEAR_MONTH_DAY) ||
        format(
          editFeedyardData?.backdate_info?.backdate,
          DATE_FORMAT.YEAR_MONTH_DAY
        ),
      feedlot_backdate_id: editFeedyardData?.backdate_info?.feedlot_backdate_id,
      feedlot_id: editFeedyardData?.feedlot_id,
      disabled_date: editFeedyardData?.backdate_info?.disabled_date,
      backdate_raw:
        format(data?.backdate, DATE_FORMAT.YEAR_MONTH_DAY) + "T19:00:00.000Z" ||
        format(
          editFeedyardData?.backdate_info?.backdate,
          DATE_FORMAT.YEAR_MONTH_DAY
        ) + "T19:00:00.000Z",
      reason: data?.reason || editFeedyardData?.backdate_info?.reason,
      validity_days:
        data?.validity_days ||
        editFeedyardData?.backdate_info?.validity_days ||
        null,
      user_id: editFeedyardData?.backdate_info?.user_id || 4,
    };

    payload.feedlot.backdate_info = backdate_info;
  }
  return payload;
};

export const parseNotifications = (data) => {
  const e = data?.response?.PBBeans?.feedlot || [];

  const obj = {
    feedlotId: e?.feedlot_id,
    feedlotManagers: e?.feedlot_managers,
    name: e?.name,
    notifications: e?.notifications,
    reviewer: e?.reviewer,
  };

  return obj;
};

export const parseImportActivities = (data) => {
  let arr = [];
  let response = data?.response?.PBBeans?.activities || [];

  response = [
    ..._.sortBy(response, [(obj) => obj.activity_number.toLowerCase()]),
  ];

  !!response?.length &&
    response.forEach((e) => {
      let res = {
        activityId: e?.activity_id,
        activity_number: e?.activity_number,
        title: e?.locale?.[1]?.title || i.t("NA"),
        status: e?.status,
        importStatus: getImportStatus(e?.import_status),
        revision: !!e?.revision_date
          ? format(
              convertDateToISOFormat(e?.revision_date),
              DATE_FORMAT.MONTH_SLASH_DAY_SLASH_YEAR_HOUR_MIN
            )
          : i.t("NA"),
        instanceBased: e?.instance_based,
        frequencyKey: e?.frequency_key,
        import: false,
        check: <i></i>,
      };
      arr.push(res);
    });

  return arr;
};

const getImportStatus = (status) => {
  return status === 0 ? (
    <Tag color="gold" className="status-tag">
      {i.t("update")}
    </Tag>
  ) : status === 1 ? (
    <Tag color="green" className="status-tag">
      {i.t("new")}
    </Tag>
  ) : (
    <Tag color="magenta" className="status-tag">
      {i.t("bin")}
    </Tag>
  );
};

const convertDateToISOFormat = (date) => {
  const [year] = date.split("-");
  if (year.length < 3) {
    const value = date.split(" ");
    const datevalue = value[0]?.split("-")?.reverse()?.join("-");
    value[0] = datevalue;
    let newdate = value.join(" ");
    if (newdate.length == 10) {
      newDate = newDate?.concat(" 00:00:00");
    }
    return new Date(newdate);
  }
  if (date?.length == 10) {
    date = date?.concat(" 00:00:00");
  }
  return new Date(date);
};

export const searchData = (data, search) => {
  const filtered = data?.filter((e) => {
    const { activity_number, name, title } = e || {};
    let found = false;
    [activity_number, name, title].forEach((x) => {
      x = x?.toLowerCase();
      const f = x && x.includes(search?.toLowerCase());
      found = f ? true : found;
    });
    return found;
  });
  return filtered;
};

export const parseAddRule = (
  data,
  groupId,
  field,
  fieldTypeKey,
  feedlotActivityId,
  ACTIVITY_FORM_KEYS
) => {
  const el = {
    field: field,
    field_type_key: fieldTypeKey,
    feedlot_activity_field_id: groupId,
    feedlot_activity_id: feedlotActivityId,
    [ACTIVITY_FORM_KEYS.fieldRuleCondition]: data?.[
      ACTIVITY_FORM_KEYS.fieldRuleCondition
    ]?.map((e) => {
      return { ...e, logical_op: e?.logical_op ?? 2 };
    }),
    [ACTIVITY_FORM_KEYS.message]: data?.[ACTIVITY_FORM_KEYS.message],
    [ACTIVITY_FORM_KEYS.fieldName]: data?.[ACTIVITY_FORM_KEYS.fieldName],
  };

  return el;
};

export const parseFeedyardListingInImport = (data) => {
  let arr = [];

  let response = data?.response?.PBBeans?.feedlots || [];
  !!response?.length &&
    response.forEach((e, index) => {
      let obj = {
        key: index,
        id: e?.feedlot_id,
        name: e?.name,
        value: e?.name,
        state: e?.state_name,
        feedlotId: e?.feedlot_id,
        import: false,
        check: <i></i>,
      };
      arr.push(obj);
    });

  return arr;
};

export const parseFeedyardListingData = (
  data,
  search = "",
  Actions,
  openFeedyardDrawer
) => {
  let arr = [];
  let response = data?.response?.PBBeans?.feedlots || [];
  !!response?.length &&
    response.forEach((e, index) => {
      let obj = {
        key: index,
        id: e?.user_id,
        name: e?.name,
        trainers: getNames(e?.trainers || []),
        feedlotManagers: getNames(e?.feedlot_managers || []),
        state: e?.state_name,
        status: getFeedyardStatus(e?.needs_attention),
        action: (
          <Actions id={e?.feedlot_id} openFeedyardDrawer={openFeedyardDrawer} />
        ),
        feedlotId: e?.feedlot_id,
        import: false,
        check: <i></i>,
      };

      !!filterFeedyardListingElement(obj, search) && arr.push(obj);
    });

  return arr;
};

export const parseFeedyardName = (data) => {
  let arr = [];
  let response = data?.response?.PBBeans?.feedlots || [];
  !!response?.length &&
    response.forEach((e, index) => {
      let obj = {
        key: e?.feedlot_id,
        id: e?.feedlot_id,
        value: e?.name,
      };
      arr.push(obj);
    });

  return arr;
};

export const parseComplianceOptions = (data) => {
  let arr = [];
  let response = data?.response?.PBBeans?.feedlot_activites || [];
  !!response?.length &&
    response.forEach((e, index) => {
      let obj = {
        key: e?.feedlot_activity_id,
        feedlotId: e?.feedlot_id,
        activityId: e?.activity_id,
        value: e?.activity_number,
      };
      arr.push(obj);
    });

  return arr;
};

export const parseComplianceCalculator = (e) => {
  const benchmark = {
    activity_numbers: e?.activities,
    feedlot_ids: [e?.feedyardId],
    start_date: e?.start_date
      ? format(e?.start_date, DATE_FORMAT.YEAR_MONTH_DAY)
      : null,
    end_date: e?.end_date
      ? format(e?.end_date, DATE_FORMAT.YEAR_MONTH_DAY)
      : null,
  };
  return benchmark;
};

const getFeedyardStatus = (status) => {
  if (status) {
    return (
      <Tag color="red" className="status-tag">
        {i.t("notOk")}
      </Tag>
    );
  }
  return (
    <Tag color="green" className="status-tag">
      {i.t("ok")}
    </Tag>
  );
};

const getNames = (arr) => {
  let names = "";

  arr?.forEach((el) => {
    const { first_name, last_name } = el?.profile || {};
    names += first_name + " " + last_name + ", ";
  });

  return names.substring(0, names.length - 2);
};

const filterFeedyardListingElement = (e, search) => {
  let searchFlag = true;
  if (!!search) {
    searchFlag = e?.name?.toLowerCase()?.includes(search?.toLowerCase());
  }

  return searchFlag;
};

export const parseEditFeedyardActivityData = (
  data,
  tabs,
  activityTagsData,
  activityDetail = [],
  editMode = false,
  ACTIVITY_FORM_KEYS
) => {
  let parsedData = {};
  parsedData.locale = {};

  parsedData.activity_number = data?.activity_number;
  parsedData.activity_type_key = data?.activity_type_key;
  parsedData.frequency_key = activityDetail?.frequencyKey;
  parsedData.activity_id = activityDetail?.activity_id;
  parsedData.feedlot_activity_id = activityDetail?.feedlotActivityId;
  parsedData.feedlot_id = activityDetail?.feedlotId;
  parsedData.documents = [];
  (parsedData.feedlot_id = activityDetail?.feedlotId),
    (parsedData.feedlot_activity_id = activityDetail?.feedlotActivityId);

  const fieldsData =
    data?.fields &&
    data?.fields?.map((field, index) => {
      return {
        field_tag: NumberToAlphabet(index + 1),
        validation_key: 1,
        group_id: field?.group_id,
        is_required: field?.is_required ? 1 : 0,
        is_absolute: field?.is_absolute ? 1 : 0,
        field_type_key: field?.field_type_key,
        ...(field?.[ACTIVITY_FORM_KEYS.showPens] && {
          [ACTIVITY_FORM_KEYS.showPens]: "pens",
        }),
        ...(field.field_type_key === FieldTypes.formula && {
          formula: field?.values?.["1_textArea"],
        }),
        values: [
          ...getFieldValues(field, tabs, index, editMode, activityDetail),
        ],
        label: {
          locale: getLocaleData(
            field,
            tabs,
            index,
            editMode,
            activityDetail,
            ACTIVITY_FORM_KEYS
          ),
        },
      };
    });
  parsedData.instance_based = data?.instance_based;
  parsedData.permission_key = data?.permission_key;
  parsedData.status = data?.status;
  parsedData.is_permitted = 1;
  parsedData.last_submitted_date = activityDetail?.lastSubmittedDate;
  parsedData.published_on = activityDetail?.publishedOn;
  parsedData.field_rules = activityDetail?.fieldRules;

  parsedData.revision_date = format(
    data?.feedyard_revision_dateTime,
    DATE_FORMAT.DATE_TIME_FORMAT
  );
  parsedData.revision_date_raw =
    data?.feedyard_revision_dateTime?.toISOString();
  parsedData.revision_time = data?.feedyard_revision_dateTime?.toISOString();

  // activity template date
  parsedData.template_revision_date = activityDetail?.templateRevisionDate;
  parsedData.template_revision_date_raw = null;

  //schedule
  //parsedData.schedule = { ...activityDetail?.schedule };

  // update schedule for feedlot schedule activity
  // ticket: https://pbfolio3.atlassian.net/browse/PBPR-6336
  parsedData.schedule = {
    ... activityDetail?.schedule ,
    frequency_calendar_key: data?.frequency_calendar_key,
  };
  
  //violation
  parsedData.violation_notification_level =
    activityDetail?.violationNotificationLevel;
  parsedData.due_date = activityDetail?.dueDate;

  if (data?.activity_type_key == ActivityEnums.Attachment) {
    parsedData.fields = AttachmentTemplate();
  } else parsedData.fields = fieldsData ? [...fieldsData] : [];

  //locale data
  parsedData.locale = {
    [tabs[0]?.key]: {
      instance_name: data?.English_instance_name
        ? data?.English_instance_name
        : "",
      instruction: data?.English_instruction ? data?.English_instruction : "",
      requirement: data?.English_requirement ? data?.English_requirement : "",
      title: data?.English_title ? data?.English_title : "",
    },
    [tabs[1]?.key]: {
      instance_name: data?.Spanish_instance_name,
      instruction: data?.Spanish_instruction,
      requirement: data?.Spanish_requirement,
      title: data?.Spanish_title,
    },
  };

  parsedData.tag_ids = [...getTagsIds(data.tag_ids, activityTagsData)];

  return { feedlot_activity: parsedData };
};

const getTagsIds = (data, activityTags) => {
  let ids = [];
  data?.forEach((tagvalue) => {
    const key = tagvalue?.value;
    const id = activityTags.find((tag) => tag.value == (key || tagvalue))?.id;
    ids.push(id);
  });
  return ids;
};

const getFieldValues = (field, tabs, index, editMode, activityDetail) => {
  let data = [];

  if (
    field.field_type_key == FieldTypeKeys.singleSelect ||
    field.field_type_key == FieldTypeKeys.multiSelect ||
    field.field_type_key == FieldTypeKeys.paragraph
  ) {
    tabs.forEach((tab) => {
      const key = `${tab.key}_textArea`;

      let values = field?.values[key];
      if (values) values = values.split(",");
      else values = [];

      for (const k in values) {
        if (data[k] == undefined) {
          data[k] = {
            locale: {},
          };
        } else if (data[k].locale == undefined) {
          data[k].locale = {};
        }
        if (data[k].locale[tabs[0].key] == undefined) {
          data[k].locale[tabs[0].key] = {};
          data[k].locale[tabs[0].key].value = "";
          data[k].locale[tabs[0].key].language_key = tabs[0].key;
        }
        if (data[k].locale[tabs[1].key] == undefined) {
          data[k].locale[tabs[1].key] = {};
          data[k].locale[tabs[1].key].value = "";
          data[k].locale[tabs[1].key].language_key = tabs[1].key;
        }
        data[k].locale[tab?.key].language_key = tab.key;
        data[k].locale[tab?.key].value = values[k];
      }
    });
  }
  return data;
};

const getLocaleData = (
  field,
  tabs,
  index,
  editMode,
  activityDetail,
  ACTIVITY_FORM_KEYS
) => {
  let data = [];

  tabs.forEach((tab) => {
    const key = tab.key + ACTIVITY_FORM_KEYS.fieldLabelValue;
    const instructionKey =
      tab.value + "_" + ACTIVITY_FORM_KEYS.instructionsValue;
    const showInstruction = tab.key + "_" + ACTIVITY_FORM_KEYS.showInstructions;
    const fieldValue = {
      language_key: tab.key,
      value: field?.label?.locale[key],
      show_instruction: field?.label?.locale[showInstruction] ? 1 : 0,
      instruction: field?.label?.locale[instructionKey],
      ...(editMode && {
        activity_field_value_id:
          activityDetail?.fieldsData &&
          activityDetail?.fieldsData[index]?.label?.locale[tab.key]
            ?.activity_field_value_id,
      }),
    };
    data.push(fieldValue);
  });

  return { ..._.mapKeys(data, "language_key") };
};

const AttachmentTemplate = () => {
  const fields = [
    {
      field_tag: "a",
      field_type_key: 11,
      is_absolute: 0,
      is_required: 1,
      label: {
        locale: {
          1: {
            language_key: 1,
            show_instruction: 0,
            title: "",
            values_text: "",
          },
          2: {
            language_key: 2,
            show_instruction: 0,
            values_text: "",
          },
        },
      },
      validation_key: "1",
      values: [],
    },
  ];
  return fields;
};

export const parseFeedlotUsers = (data) => {
  let arr = [];
  let response = data?.response?.PBBeans?.users || [];
  !!response?.length &&
    response.forEach((e, index) => {
      if (e?.status) {
        let obj = {
          userId: e?.user_id,
          key: "" + e?.user_id,
          firstName: e?.profile?.first_name,
          lastName: e?.profile?.last_name,
          value: e?.profile?.first_name + " " + e?.profile?.last_name,
        };
        arr.push(obj);
      }
    });

  return arr;
};
