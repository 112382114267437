/*
  Breadcrums
*/

import React from "react";

// Redux
import {
  useSelector
} from 'react-redux';

import { Link } from "react-router-dom";

// Translate
import { useTranslation } from 'react-i18next';

import { 
  Breadcrumb
} from 'antd';

const Breadcrumbs = () => {
  const { t } = useTranslation();

  // Redux States
  const { data } = useSelector((state) => state?.Breadcrumb);

  return (
    <Breadcrumb separator=">">
      {
        data?.map((el, index) => {
          const { label, url } = el || {};

          return(
            <Breadcrumb.Item key={ index }>
              {
                !!url? 
                  <Link to={ url }>{ t(label) }</Link>:
                  <>{ t(label) }</>
              }
            </Breadcrumb.Item>      
          )
        })
      }
    </Breadcrumb>
  );
};

export default Breadcrumbs;