import { LOCAL_STORAGE_KEYS, PROCESSING_AUDITS, PENDING_JOBS } from '../../constants/General';

export default class StorageService {
  static instance = null;

  // Constuctor
  constructor(storage) {
    this.localStorage = storage;
  }

  // Set Access Token
  setAccessToken(accessToken) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
      accessToken || null
    );
  }

  // Get Access Token
  getAccessToken() {
    try {
      const token = this.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
      return token;
    } catch (error) {
      return '';
    }
  }

  // Set Refresh Token
  setRefreshToken(token) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
      token || null
    );
  }

  // Get Refresh Token
  getRefreshToken() {
    try {
      const token = this.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
      return token;
    } catch (error) {
      return '';
    }
  }

  // Set User Info
  setUserInfo(info) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER_INFO,
      JSON.stringify(info || {})
    );
  }

  // Get User Info
  getUserInfo() {
    try {
      const info = this.localStorage.getItem(LOCAL_STORAGE_KEYS.USER_INFO);
      return JSON.parse(info);
    } catch (error) {
      return '';
    }
  }

  // Set Permissions
  setPermissions(permissions) {
    this.localStorage.setItem(
      LOCAL_STORAGE_KEYS.PERMISSIONS,
      JSON.stringify(permissions || {})
    );
  }

  // Get User Info
  getPermissions() {
    try {
      const permissions = this.localStorage.getItem(LOCAL_STORAGE_KEYS.PERMISSIONS);
      return JSON.parse(permissions);
    } catch (error) {
      return '';
    }
  }

  // set processing audits

  setProcessingAudits(info) {
    this.localStorage.setItem(
      PROCESSING_AUDITS,
      JSON.stringify(info || {})
    );
  }

  // Get processing audits
  getProcessingAudits() {
    try {
      const info = this.localStorage.getItem(PROCESSING_AUDITS);
      return JSON.parse(info);
    } catch (error) {
      return [];
    }
  }

  // set pending report jobs for this session
  setPendingJobs(info){
    this.localStorage.setItem(
      PENDING_JOBS,
      JSON.stringify(info || [])
    );
  }

  getPendingJobs() {
    var pending_jobs = [];
    try {
      const info = this.localStorage.getItem(PENDING_JOBS);
      if(info){
        pending_jobs = JSON.parse(info);
      }
      return pending_jobs; 
    } catch (error) {
      return [];
    }
  }

  deleteLoginData() {
    this.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    this.localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    this.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_INFO);
    this.localStorage.removeItem(LOCAL_STORAGE_KEYS.PERMISSIONS);
    this.localStorage.removeItem(PROCESSING_AUDITS);
    this.localStorage.removeItem(PENDING_JOBS);
    // this.localStorage.removeItem(LOCAL_STORAGE_KEYS.OFFLINE_IMAGE);
  }
}