/* 
  Header Drawer
*/

import React, { useEffect, useState } from "react";

// Redux
import { useDispatch } from "react-redux";

// Redux
import { useSelector } from "react-redux";

// Translate
import { useTranslation } from "react-i18next";

// Constants
import { REDUX_STATES } from "../../../../../constants/ReduxStates";
import { DATE_FORMAT } from "../../../../../constants/DateFormat";
import { API_URLS } from "../../../../../constants/ApiConstants";

// Data Parser
import {
  parseFeedyardName,
  parseComplianceOptions,
} from "../../../../../dataParser/Feedyard";

// Form Elements
import {
  Picker as DatePicker,
  Dropdown,
  MultiSelect,
} from "../../../../Common/FormElements";

// Actions;
import { getList } from "../../../../../store/actions/CRUDAction";

// dayjs
import dayjs from "dayjs";

//helpers
import { errorNotification} from "../../../../../helpers/Notification"

const { ALL_FEEDYARDS, FOR_COMPLIANCE, VIEW } = REDUX_STATES;


function ComplianceCalculator({ form, open }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //local state
  const [complianceData, setComplianceData] = useState([]);

  //redux state
  const { [ALL_FEEDYARDS + VIEW]: feedlots = {} } = useSelector(
    (state) => state?.Crud
  );

  const feedyardNames = parseFeedyardName(feedlots);

  useEffect(() => {
    form?.setFieldsValue({
      ["start_date"]: new Date(),
      ["end_date"]: new Date(),
    });
  }, [open]);

  const clearFields = () => {
    form?.setFieldsValue({
      ['activities']: undefined
    });
    setComplianceData([]);
  }


  const onFeedyardChange = (id) => {
    clearFields();

    dispatch(
      getList(
        API_URLS.ACTIVITIES_INCLUDED.replace(":id", id),
        {},
        FOR_COMPLIANCE
      )
    ).then(
      (res) => {
        const compliance = parseComplianceOptions(res);
      
        setComplianceData(compliance);
      },
      // () => {
      //   errorNotification(t("errorFeedyardRankList"));
  
      // }
    );
  };

  const disabledDate = (current) => {
    return current > dayjs().endOf("day");
  };

  return (
    <div>
      <Dropdown
        name={"feedyardId"}
        label={t("feedyardName")}
        options={feedyardNames}
        required={true}
        message={t("feedyardNotSelected")}
        placeholder={t("selectFeedyard")}
        onChange={(key) => onFeedyardChange(key)}
      />

      <MultiSelect
        name={"activities"}
        label={t("activitesIncluded")}
        options={complianceData}
        disabled={!complianceData?.length}
        placeholder={t("selectActivity")}
        allowClear={true}
        showSearch={true}
        required={true}
        message={t("activitesNotSelected")}
        tagCount={4}
        showSelectAll={ true }
        form={ form }
      />

      <DatePicker
        name={"start_date"}
        label={t("startDate")}
        required={true}
        format={DATE_FORMAT.MONTH_DAY_YEAR}
        disabledDate={disabledDate}
      />

      <DatePicker
        name={"end_date"}
        label={t("endDate")}
        required={true}
        format={DATE_FORMAT.MONTH_DAY_YEAR}
        disabledDate={disabledDate}
      />
    </div>
  );
}

export default ComplianceCalculator;
