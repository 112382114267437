/*
  Input Number
*/

import React, { useState, useEffect } from "react";

// Antd
import { Form, InputNumber, message, Space } from "antd";

// Translate
import { useTranslation } from "react-i18next";

// Style
import "./style.scss";

function Number({
  name,
  label,
  placeholder,
  parser,
  defaultValue,
  precision,
  onChange,
  disabled,
  required,
  min,
  max,
  step,
  validateTrigger,
  validator,
  onblur,
  readOnly,
  notwrapInForm,
  formatter,
  tooltip,
  hidden,
  warning,
  message,
  pattern,
}) {
  const { t } = useTranslation();

  //let warningTimer;

  const [warningState, setWarningState] = useState(!!warning);

  useEffect(() => {
    setWarningState(warning);
  }, [warning]);

  // Rules
  let rules = [
    { required: required, message: message || t("Required") },
    ...(validator || []),
    {
      pattern: pattern || "",
      message: t("negativeNumber"),
    },
  ];

  // if (!!validator) {
  //   rules.push({ validator: validator });
  // }

  // Number Field
  const NumberField = (
    // <Space direction="vertical" style={{ width: "100%" }}>
    <InputNumber
      placeholder={placeholder || label}
      onChange={(e) => {
        onChange && onChange(e);
        setWarningState(false);
      }}
      disabled={disabled}
      min={min}
      max={max}
      step={step}
      precision={precision}
      onBlur={onblur}
      value={defaultValue}
      readOnly={readOnly}
      formatter={formatter}
      parser={parser}
      className="w-100"
    />
    // </Space>
  );

  return !!notwrapInForm ? (
    <>{NumberField}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
      tooltip={tooltip}
      hidden={hidden}
      className={warningState ? "inputNumber ant-form-item-has-error" : ""}
      //validateStatus={warning && 'error'}
    >
      {NumberField}
    </Form.Item>
  );
}

export default Number;
