import { lazy } from "react";

// Constants
import APP_URL from "./constants/AppRoutes";

import { PERMISSIONS } from "./constants/Permissions";

const {
  DASHBOARD,
  FEEDYARDS,
  USERS,
  ACTIVITIES,
  VIDEOS,
  REPORTS,
  AUDITS,
  CHANGE_PASSWORD,
  SOPS
} = PERMISSIONS;

const routes = [
  {
    name: "login",
    path: APP_URL.LOGIN,
    component: lazy(() =>
      import("./components/Authentication/Login")
    ),
  },
  {
    name: "dashboard",
    path: APP_URL.DASHBOARD,
    component: lazy(() => import("./components/Dashboard")),
    permissions: DASHBOARD,
  },

  // Program Manualls
  // {
  //   name: "manageProgramManuals",
  //   path: APP_URL.PROGRAM_MANUAL,
  //   component: lazy(() => import("./components/ProgramManuals/List")),
  //   requireAuthentication: true,
  // },
  // {
  //   name: "addDocument",
  //   path: APP_URL.ADD_DOCUMENT,
  //   component: lazy(() => import("./components/ProgramManuals/AddDocument")),
  //   requireAuthentication: true,
  // },

  // Feedyard
  // {
  //   name: "manageFeedyards",
  //   path: APP_URL.FEEDYARDS,
  //   component: lazy(() => import("./components/Feedyards")),
  //   permissions: FEEDYARDS,
  // },
  {
    name: "manageFeedyard",
    path: APP_URL.FEEDYARDS,
    component: lazy(() =>
      import("./components/Feedyards/List")
    ),
    permissions: FEEDYARDS,
  },

  // ACTIVITIES_AND_USERS
  {
    name: "activitiesAndUsers",
    path: APP_URL.ACTIVITIES_AND_USERS,
    component: lazy(() =>
      import("./components/Feedyards/ActivitiesAndUsers")
    ),
    permissions: FEEDYARDS,
  },

  // view activity in feedyard level
  {
    name: "viewActivity",
    path: APP_URL.VIEW_FEEDYARD_ACTIVITY,
    component: lazy(() =>
      import("./components/Activities/View")
    ),
    permissions: ACTIVITIES,
  },

  //update activity in feedyard level
  {
    name: "updateFeedyardActivity",
    path: APP_URL.UPDATE_FEEDYARD_ACTIVITY,
    component: lazy(() =>
      import("./components/Activities/Add")
    ),
    permissions: ACTIVITIES,
  },

  // Users
  {
    name: "addFeedyard",
    path: APP_URL.ADD_FEEDYARD,
    component: lazy(() =>
      import("./components/Feedyards/AddEditFeedyard")
    ),
    // requireAuthentication: true,
    permissions: FEEDYARDS,
  },

  {
    name: "editFeedyard",
    path: APP_URL.UPDATE_FEEDYARD,
    component: lazy(() =>
      import("./components/Feedyards/AddEditFeedyard")
    ),
    // requireAuthentication: true,
    permissions: FEEDYARDS,
  },

  {
    name: "manageUsers",
    path: APP_URL.USERS,
    component: lazy(() =>
      import("./components/Users/List")
    ),
    permissions: USERS,
  },

  // Activites
  {
    name: "activites",
    path: APP_URL.ACTIVITIES,
    component: lazy(() =>
      import("./components/Activities/List")
    ),
    permissions: ACTIVITIES,
  },
  {
    name: "addActivity",
    path: APP_URL.ADD_ACTIVITY,
    component: lazy(() =>
      import("./components/Activities/Add")
    ),
    permissions: ACTIVITIES,
  },

  {
    name: "viewActivity",
    path: APP_URL.VIEW_ACTIVITY,
    component: lazy(() =>
      import("./components/Activities/View")
    ),
    permissions: ACTIVITIES,
  },
  {
    name: "updateActivity",
    path: APP_URL.UPDATE_ACTIVITY,
    component: lazy(() =>
      import("./components/Activities/Add")
    ),
    permissions: ACTIVITIES,
  },

  // Videos
  {
    name: "appVideos",
    path: APP_URL.APP_VIDEO,
    component: lazy(() => import("./components/Videos")),
    permissions: VIDEOS,
  },

  {
    name: "PbVideo",
    path: APP_URL.PB_VIDEO,
    component: lazy(() => import("./components/PbVideos")),
    permissions: VIDEOS,
  },

  // Reports
  {
    name: "reports",
    path: APP_URL.AUDIT_REPORTS,
    component: lazy(() => import("./components/Reports")),
    permissions: REPORTS,
  },

  {
    name: "custom-reports",
    path: APP_URL.CUSTOM_REPORTS,
    component: lazy(() =>
      import("./components/Reports/CustomReports")
    ),
    permissions: REPORTS,
  },

  {
    name: "compliance-reports",
    path: APP_URL.COMPLIANCE_REPORTS,
    component: lazy(() =>
      import("./components/Reports/ComplianceReports")
    ),
    permissions: REPORTS,
  },
  // Audits
  {
    name: "manageAudits",
    path: APP_URL.AUDITS,
    component: lazy(() =>
      import("./components/Audits/List")
    ),
    permissions: AUDITS,
  },

  {
    name: "viewAudit",
    path: APP_URL.VIEW_AUDIT,
    component: lazy(() =>
      import("./components/Audits/View")
    ),
    permissions: AUDITS,
  },

  {
    name: "viewAuditSection",
    path: APP_URL.VIEW_AUDIT_SECTION,
    component: lazy(() =>
      import(
        "./components/Audits/View/Sections/ViewSection"
      )
    ),
    permissions: AUDITS,
  },
  // forget password
  {
    name: "forgetPassword",
    path: APP_URL.RESET_PASSWORD,
    component: lazy(() =>
      import("./components/Authentication/ResetPassword")
    ),
  },
  {
    name: "changePassword",
    path: APP_URL.CHANGE_PASSWORD,
    component: lazy(() =>
      import("./components/ChangePassword")
    ),
    permissions: CHANGE_PASSWORD,
  },

  // view submission of activity in feedyard level
  {
    name: "viewASubmission",
    path: APP_URL.VIEW_FEEDYARD_SUBMISSION,
    component: lazy(() =>
      import("./components/Activities/List/ViewSubmission")
    ),
    permissions: ACTIVITIES,
  },
  // SOPs
  {
    name: "manageSops",
    path: APP_URL.SOP,
    component: lazy(() =>
      import("./components/Sops/List")
    ),
    permissions: SOPS,
  },
];

export default routes;
