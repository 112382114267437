import React, { useState, useMemo, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Drawer, Input } from "antd";

// Antd
import { Form } from "antd";

// Translate
import { useTranslation } from "react-i18next";

// Style
import "./style.scss";

const toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [
    { list: "ordered" },
    { list: "bullet" },
    { indent: "-1" },
    { indent: "+1" },
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" }, // Selector for toolbar container
  ],
  ["link", "image", "code"],
  [{ script: "super" }, { script: "sub" }],
];

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "code",
  "align",
  "image",
  "script",
];

const { TextArea } = Input;

const TextEditor = ({
  name,
  label,
  placeholder,
  defaultValue,
  onChange,
  required,
  validateTrigger,
  validator,
  notwrapInForm,
  message,
  form,
}) => {
  const { t } = useTranslation();

  // Rules
  let rules = [
    { required: required, message: message ? message : t("Required") },
    ...(validator || []),
  ];

  const [editor_html, setEditorHTML] = useState("");
  const [raw_html, setRawHTML] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const quilRef = useRef("");

  const showModal = () => {
    handleClickShowRaw();
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setEditorHTML(raw_html);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClickShowRaw = () => {
    setRawHTML(quilRef.current?.value);
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbar,
        handlers: {
          code: showModal,
        },
      },
    }),
    []
  );

  useEffect(() => {
    !!defaultValue && setEditorHTML(defaultValue);
    !!form && setEditorHTML(form.getFieldValue(name));
  }, [!!form && form.getFieldValue(name), !!defaultValue]);

  const RichText = (
    <>
      <ReactQuill
        onChange={(d) => {
          setEditorHTML(quilRef.current?.value);
          !!form && form.setFieldValue(name, quilRef.current?.value);
          !!onChange && onChange(d);
        }}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
        value={editor_html}
        theme={"snow"}
        ref={quilRef}
      />
      <Drawer
        title={t("sourceCode")}
        open={isModalOpen}
        // onOk={handleOk}
        onClose={handleCancel}
        footer={
          <div className="h-end">
            <Button type="text" key="1" onClick={handleCancel}>
              {t("cancel")}
            </Button>

            <Button key="2" type="primary" htmlType="submit" onClick={handleOk}>
              {t("ok")}
            </Button>
          </div>
        }
      >
        <TextArea
          onChange={(e) => setRawHTML(e.target.value)}
          value={raw_html}
        />
      </Drawer>
    </>
  );
  return !!notwrapInForm ? (
    <>{RichText}</>
  ) : (
    <>
      <Form.Item
        name={name}
        label={label}
        validateTrigger={validateTrigger || "onBlur"}
        rules={rules}
        data-testid="form"
        className="text-editor"
      >
        {RichText}
      </Form.Item>
    </>
  );
};

export default TextEditor;
