// Lodash
import _ from "lodash";

export const parseFeedyardRankListingData = (
  data,
  search = ""
) => {
  let arr = [];
  let response = data?.response?.PBBeans?.feedlot_ranks || [];

  !!response?.length &&
    response.forEach(e => {
      const { rank } = e || {};

      if (!!search) {
        if(rank?.feedlot_name?.toLowerCase()?.includes(search?.toLowerCase())) {
          arr.push(rank); 
        }
      }else {
        arr.push(rank); 
      }
    });

  return _.sortBy(arr, "position");
};