import React from "react";

// antd
import { Button } from "antd";

// Translate
import { useTranslation } from "react-i18next";

const ComplianceButtons = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className="h-end">
      <Button type="text" key="1" onClick={onClose}>
        {t("Cancel")}
      </Button>

      <Button key="2" type="primary" onClick={onSubmit}>
        {t("exportReport")}
      </Button>
    </div>
  );
};

export default ComplianceButtons;
