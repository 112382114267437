import React, { useState, useMemo, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button, Drawer, Input, Form,Dropdown } from "antd";
import { HighlightOutlined,DeleteOutlined } from "@ant-design/icons";



// Translate
import { useTranslation } from "react-i18next";

// Style
import "./style.scss";
import { SUBMISSION_STATUS } from "../../../../constants/General";

const toolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike", "blockquote"],
  [
    { list: "ordered" },
    { list: "bullet" },
    { indent: "-1" },
    { indent: "+1" },
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" }, // Selector for toolbar container
  ],
  ["link", "image", "code"],
  [{ script: "super" }, { script: "sub" }],
];

const formats = ['color'];

const modules2 = {
  toolbar: [
    [{ 'color': ['#ff0000'] }], // Set the color options to red and black
    ['clean'],
  ],
};

const modules = {
  toolbar: false
}

const { TextArea } = Input;

const HighlightableTextarea = ({
  name,
  label,
  placeholder,
  defaultValue,
  onChange,
  required,
  validateTrigger,
  validator,
  notwrapInForm,
  message,
  form,
  section,
  question,
  ncIndex,
  type,
  removeNc,
  nc,
  getQuestionNames,
  nc_ca_items,
  status,
  setNcCaItems,
  visible,
  handleVisibleChange,
}) => {
  const { t } = useTranslation();

  // Rules
  let rules = [
    { required: required, message: message ? message : t("Required") },
    ...(validator || []),
  ];

  const [editor_html, setEditorHTML] = useState("");
  const [isRedText, setIsRedText] = useState(false);
  const [raw_html, setRawHTML] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const quilRef = useRef("");

  useEffect(() => {
  }, [isError,isRedText]);

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const validateSpecialCharacters = (value) => {
    value = stripHtmlTags(value);
    const regex = /[<>[\]]/;
    const hasError = regex.test(value);
    return hasError ? Promise.reject(t("ncSpecialCharacterError")) : Promise.resolve();
  };

  const handleToggleRedColor = (e) => {
    e.preventDefault();
    e.stopPropagation();
  
    // Use the callback function of setIsRedText to ensure the state is updated
    setIsRedText(prevIsRedText => {
      const newIsRedText = !prevIsRedText;  
      const quill = quilRef.current.editor; // Access the Quill instance
      quill.format('color', newIsRedText ? 'red' : '');
  
      return newIsRedText;
    });
  };
  

  const showModal = () => {
    handleClickShowRaw();
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setEditorHTML(raw_html);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClickShowRaw = () => {
    setRawHTML(quilRef.current?.value);
  }

  const updateNcCaItems = (nc) => {
    setNcCaItems((prevItems) =>
      prevItems.map((item) =>
        item.id === nc.id ? { ...item, description: nc.description } : item
      )
    );
  };
  

  useEffect(() => {
    !!defaultValue && setEditorHTML(defaultValue);
    !!form && setEditorHTML(form.getFieldValue(name));
  }, [!!form && form.getFieldValue(name), !!defaultValue]);

  const RichText = (
    <>
      {
        status == SUBMISSION_STATUS.DRAFT && (
          <div className="action-icons">
            {
              nc?.audit_question_nc_ca_id && (
                <div className="position-relative2">
                  <Dropdown
                    overlay={() => getQuestionNames(nc, type)}
                    trigger={["click"]}
                    overlayClassName={"menu-drop"}
                    open={visible}
                    onOpenChange={handleVisibleChange}
                  >
                    <button
                      className="copyIcon cursor-pointer  d-inline mt-2"
                      onClick={(e) => {
                        // set visible as undefined to work as default
                        handleVisibleChange(undefined, nc.audit_question_nc_ca_id);
                        e?.stopPropagation();
                        e?.preventDefault();
                      }}
                    >
                    
                    </button>
                  </Dropdown>
                </div>
              )
            }
            <Button
              className={`${nc_ca_items?.length > 1 ? '' : 'disabled2'} deleteIcon cursor-pointer d-inline mt-2 mr-3`}
              type="text"
              // disabled={nc_ca_items?.length == 1}
              onClick={(e) => {
                  e?.stopPropagation();
                  e?.preventDefault();
                  removeNc(nc.id);
                  // if(nc_ca_items.length > 1){
                  //   removeNc(nc.id);
                  // }
                } 
              }
              >
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12.5C5 12.7812 4.75 13 4.5 13C4.21875 13 4 12.7812 4 12.5V6C4 5.75 4.21875 5.5 4.5 5.5C4.75 5.5 5 5.75 5 6V12.5ZM7.5 12.5C7.5 12.7812 7.25 13 7 13C6.71875 13 6.5 12.7812 6.5 12.5V6C6.5 5.75 6.71875 5.5 7 5.5C7.25 5.5 7.5 5.75 7.5 6V12.5ZM10 12.5C10 12.7812 9.75 13 9.5 13C9.21875 13 9 12.7812 9 12.5V6C9 5.75 9.21875 5.5 9.5 5.5C9.75 5.5 10 5.75 10 6V12.5ZM9.90625 0.78125L11.0625 2.5H13.25C13.6562 2.5 14 2.84375 14 3.25C14 3.6875 13.6562 4 13.25 4H13V13.5C13 14.9062 11.875 16 10.5 16H3.5C2.09375 16 1 14.9062 1 13.5V4H0.75C0.3125 4 0 3.6875 0 3.25C0 2.84375 0.3125 2.5 0.75 2.5H2.90625L4.0625 0.78125C4.375 0.3125 4.9375 0 5.53125 0H8.4375C9.03125 0 9.59375 0.3125 9.90625 0.78125ZM4.71875 2.5H9.25L8.65625 1.625C8.625 1.5625 8.53125 1.5 8.4375 1.5H5.53125C5.4375 1.5 5.34375 1.5625 5.3125 1.625L4.71875 2.5ZM2.5 13.5C2.5 14.0625 2.9375 14.5 3.5 14.5H10.5C11.0312 14.5 11.5 14.0625 11.5 13.5V4H2.5V13.5Z" fill="#635F5F"/>
              </svg>
            </Button>
            <Button
              type="text"
              className={`${isRedText ? "active" : ''} hightLighticon cursor-pointer d-inline mt-2 mr-3`}
              onClick={handleToggleRedColor}
              >
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.46875 10V7.78125C4.46875 7.28125 4.71875 6.84375 5.09375 6.5625L13.625 0.28125C13.875 0.09375 14.125 0 14.4375 0C14.7812 0 15.125 0.15625 15.375 0.40625L17.0938 2.125C17.3438 2.375 17.4688 2.71875 17.4688 3.0625C17.4688 3.34375 17.4062 3.625 17.2188 3.875L10.9375 12.4062C10.6562 12.7812 10.1875 13 9.71875 13H7.46875L6.6875 13.8125C6.3125 14.1875 5.65625 14.1875 5.28125 13.8125L3.6875 12.2188C3.3125 11.8438 3.3125 11.1875 3.6875 10.8125L4.46875 10ZM15.2812 3.125L14.375 2.21875L7.65625 7.15625L10.3125 9.84375L15.2812 3.125ZM0.71875 14.5938L2.6875 12.625L4.875 14.8125L3.90625 15.7812C3.78125 15.9375 3.59375 16 3.375 16H1.25C0.8125 16 0.5 15.6875 0.5 15.25V15.125C0.5 14.9062 0.5625 14.7188 0.71875 14.5938Z" strokeWidth="2" fill="#F90505"/>
              </svg>
            </Button>
          </div>
        )
      }
      <ReactQuill
        onChange={(d, delta, source, editor) => {
          const value = quilRef.current?.value.trim();
      
          // Check if the content is empty
          const isEmpty = value === '<p><br></p>';
      
          // Use the actual value or an empty string if it's empty
          let sanitizedValue = isEmpty ? "" : value;

          // Remove empty span tags with color: red; style
          if (sanitizedValue && typeof sanitizedValue.replace === 'function') {
            sanitizedValue = sanitizedValue.replace(/<span style="color: red;">\s*<\/span>/g, '');
          }
          // Remove color styles other than red
          if (sanitizedValue && typeof sanitizedValue.replace === 'function') {
            sanitizedValue = sanitizedValue.replace(/<span style="color:\s*(?:(?!red).)*;">([^<]*)<\/span>/gi, '$1');
          }

      
          setEditorHTML(sanitizedValue);

          // Validate special characters synchronously
          const value2 = stripHtmlTags(sanitizedValue);
          const regex = /[<>[\]]/;
          const hasError = regex.test(value2);

          if (hasError) {
            setIsError(true);
            form && form.setFields([{ name, errors: [t("ncSpecialCharacterError")] }]);
          } else {
            setIsError(false);
            form && form.setFields([{ name, errors: [] }]);
          }

          try{
            // Access the Quill instance
            const quill = quilRef.current.editor;

            // Get the current selection
            const selection = quill.getSelection();

            // Check if the selection is inside a red-colored text
            const isSelectionInRedText = quill.getFormat(selection)?.color === 'red';

            // If isRedText is false and the selection is in red text, remove the red color
            if (!isRedText && isSelectionInRedText) {
              quill.formatText(selection.index, selection.length, 'color', false);
            }

            const currentColor = quill.getFormat().color;
            // Check if the current color is red and update the state accordingly
            setIsRedText(currentColor === 'red');
          } catch(error){
            console.log("quil selection null!!!")
          }
      
          !!form && form.setFieldValue(name, sanitizedValue);
          nc.description = sanitizedValue;
          nc.hasError = hasError;
          updateNcCaItems(nc);
          
          !!onChange && onChange(d);
        }}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
        value={editor_html}
        theme={"snow"}
        ref={quilRef}
        readOnly={status != SUBMISSION_STATUS.DRAFT}
      />
      <Drawer
        title={t("sourceCode")}
        open={isModalOpen}
        // onOk={handleOk}
        onClose={handleCancel}
        footer={
          <div className="h-end">
            <Button type="text" key="1" onClick={handleCancel}>
              {t("cancel")}
            </Button>

            <Button key="2" type="primary" htmlType="submit" onClick={handleOk}>
              {t("ok")}
            </Button>
          </div>
        }
      >
        <TextArea
          onChange={(e) => setRawHTML(e.target.value)}
          value={raw_html}
        />
      </Drawer>
    </>
  );
  return !!notwrapInForm ? (
    <>{RichText}</>
  ) : (
    <>
      <Form.Item
        name={name}
        label={label}
        validateTrigger={validateTrigger || "onBlur"}
        rules={rules}
        data-testid="form"
        className={`text-editor ${isError ? "error" : ""}`}
      >
        {RichText}
      </Form.Item>
    </>
  );
};

export default HighlightableTextarea;
