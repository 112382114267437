export const ActivityTypes = [
  { key: 1, value: "Log" },
  { key: 2, value: "Form" },
  { key: 3, value: "Attachment" },
];

export const InstanceTypes = [
  { key: 1, value: "Yes" },
  { key: 0, value: "No" },
];
export const ActivityEnums = {
  log: 1,
  Form: 2,
  Attachment: 3,
};

export const RULE_TAB = {
  title: "Rule(s)",
  view: "field_rule",
  index: 3,
};

export const INSTANCE_TAB = {
  title: "Instance(s)",
  view: "instances",
  index: 4,
};

export const RULES = {
  Equals: 1,
  Contains: 2,
  "Starts With": 3,
  "Ends With": 4,
  "Greater Than": 5,
  "Less Than": 6,
  Before: 7,
  After: 8,
};

export const FieldTypeKeys = {
  singleSelect: 3,
  multiSelect: 4,
  paragraph: 8,
  formula: 9,
  // instruction: 12,
};

export const RequiredFrequencyEnums = {
  eventBased: 1,
  required: 2,
  feedlotScheduled: 3,
  specialLog: 4,
};

export const InstanceEnums = {
  yes: 1,
  no: 0,
};

export const SubmissionStatus = {
  archived: 1,
  publish: 2,
  draft: 3,
  bin: 5,
};

export const LanguageKeys = {
  English: 1,
  Spanish: 2,
};

export const LOG21 = "log21";

export const FieldTypes = {
  text: 1,
  integer: 2,
  singleSelect: 3,
  multiSelect: 4,
  decimal: 5,
  date: 6,
  textArea: 7,
  paragraph: 8,
  formula: 9,
  employees: 10,
  attachment: 11,
  instruction: 12,
};

export const OPERATIONS = [
  {
    key: 1,
    value: "Equals",
    types: [
      FieldTypes.integer,
      FieldTypes.decimal,
      FieldTypes.formula,
      FieldTypes.singleSelect,
      FieldTypes.date,
      FieldTypes.employees,
      FieldTypes.textArea,
    ],
  },
  {
    key: 2,
    value: "Contains",
    types: [
      FieldTypes.singleSelect,
      FieldTypes.text,
      FieldTypes.multiSelect,
      FieldTypes.textArea,
    ],
  },
  {
    key: 3,
    value: "Start With",
    types: [FieldTypes.text, FieldTypes.textArea],
  },
  {
    key: 4,
    value: "Ends With",
    types: [FieldTypes.text, FieldTypes.textArea],
  },
  {
    key: 5,
    value: "Greater Than",
    types: [FieldTypes.integer, FieldTypes.decimal, FieldTypes.formula],
  },
  {
    key: 6,
    value: "Less Than",
    types: [FieldTypes.integer, FieldTypes.decimal, FieldTypes.formula],
  },
  {
    key: 7,
    value: "Before",
    types: [FieldTypes.date],
  },
  {
    key: 8,
    value: "After",
    types: [FieldTypes.date],
  },
];

export const LOGICAL_OPERATORS = [
  { key: 1, value: "AND" },
  { key: 2, value: "OR" },
];

export const USER_ROLES = [
  { key: 3, value: "Feedlot manager" },
  { key: 4, value: "Supervisor" },
];

export const FrequencyCalenderKeyEnums = {
  yearly: 1,
  quarterly: 2,
  monthly: 3,
  weekly: 4,
  daily: 5,
  thisWeek: 6,
  thisMonth: 7,
  biAnnualy: 8,
  every12Month: 9,
};