import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Constants
import routes from "../routes";
import APP_ROUTES from "../constants/AppRoutes";

// Components
import PrivateRoute from "../components/PrivateRoute";
import Loading from "../components/Loading";

// Helpers
import { ispermssionsExist } from "../helpers/Permissions";

// Services
import StorageService from "../services/StorageService/index";

// Translate
import { useTranslation } from 'react-i18next';

function AppRouter() {
  const { t } = useTranslation();

  const { user_role_name = "" } = StorageService.instance.getUserInfo() || {};

  return (
    // <Suspense fallback={<Loading />}>
    <Routes>
      {/* Open Routes */}
      {routes
        .filter((route) => !route.permissions)
        .map(({ name, path, component: Component }) => (
          <Route
            exact
            key={name}
            path={path}
            element={
              <Suspense fallback={<Loading />}>
                <Component title={t(name)} />
              </Suspense>
            }
          />
        ))}

      {/* Private Routes */}
      <Route exact path="/" element={<PrivateRoute />}>
        {routes
          .filter((route) => !!route.permissions)
          .map(({ name, path, component: Component, permissions }) => (
            //permissions?.includes(user_role_name) && (
            <Route
              exact
              key={name}
              path={path}
              element={
                <Suspense fallback={<Loading />}>
                  <Component title={t(name)} />
                </Suspense>
              }
            />
            //)
          ))}
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <Navigate to={APP_ROUTES.DASHBOARD} />{" "}
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <Navigate to={APP_ROUTES.DASHBOARD} />{" "}
            </Suspense>
          }
        />
      </Route>
    </Routes>
    // </Suspense>
  );
}

export default AppRouter;
