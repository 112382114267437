/* 
  Header Drawer
*/

import React from "react";

// Antd
import { Drawer, Form, Modal } from "antd";

// Redux
import { useDispatch } from "react-redux";

// Components
import FeedyardRank from "./FeedyardRank";
import ComplianceCalculator from "./ComplianceCalculator";
import ComplianceButtons from "./ComplianceButtons/index.js";

// Translate
import { useTranslation } from "react-i18next";

//helper
import { parseComplianceCalculator } from "../../../../dataParser/Feedyard";

// Actions;
import { addRequest, getList } from "../../../../store/actions/CRUDAction";

//constants
import { API_URLS } from "../../../../constants/ApiConstants";
import { REDUX_STATES } from "../../../../constants/ReduxStates";

import StorageService from "../../../../services/StorageService";

import {
  successNotification,
  errorNotification,
} from "../../../../helpers/Notification";

const { DOWNLOAD_EXCEL_REPORT, VIEW } = REDUX_STATES;

function HeaderDrawer({ drawer, onClose, form }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //form reference

  const getNotificationData = () => {
    dispatch(
      getList(
        API_URLS.DASHBOARD_NOTIFICATION_ADMIN,
        {},
        REDUX_STATES.DASHBOARD_NOTIFICATION_ADMIN
      )
    );
  };

  const pushToLocalStoragePendingJobs = (jsonItem) => {
    // Retrieve the array from state
    var jobsArray  = StorageService.instance.getPendingJobs();
    if(!jobsArray) jobsArray = [];
  
    // Push the JSON item to the array
    jobsArray.push(jsonItem);
  
    StorageService.instance.setPendingJobs(jobsArray);
  };

  const onSubmit = (e) => {
    const values = parseComplianceCalculator(e);

    if (
      new Date(values?.start_date)?.getTime() >
      new Date(values?.end_date)?.getTime()
    ) {
      Modal.error({
        title: "Error",
        content: t("endDateNotEqStartDate"),
      });
      return;
    }
    dispatch(
      addRequest(
        API_URLS.COMPLIANCE_REPORT_FX,
        { benchmark: values },
        {},
        DOWNLOAD_EXCEL_REPORT
      )
    )
      .then((res) => {
        if (res?.data?.response?.status) {
          if(res?.data?.response?.code === "_00857"){
            if(res?.data?.response?.PBBeans?.report_job){
              var json_item = res?.data?.response?.PBBeans?.report_job;
              pushToLocalStoragePendingJobs(json_item);
            }
            successNotification(t("reportGenerationSuccess"));
            onClose();
          } else {
            successNotification(t("reportSuccess"));
            onClose();
            getNotificationData();
          }
        } else if (!res?.data?.response?.status) {
          errorNotification(res?.data?.response?.message);
        }
      })

      .catch((error) => {
        errorNotification(t("networkError"));
      });
  };

  return (
    <Drawer
      title={
        drawer === 1 ? (
          t("feedyardRank")
        ) : (
          <>
            {t("complianceCalculator")} <i>{t("fx")}</i>
          </>
        )
      }
      footer={
        drawer == 2 ? (
          <ComplianceButtons
            onClose={onClose}
            onSubmit={() => form?.submit()}
          />
        ) : null
      }
      placement="right"
      className="imprt-activity"
      maskClosable={false}
      onClose={onClose}
      open={!!drawer}
    >
      <Form
        form={form}
        layout={"vertical"}
        onFinish={onSubmit}
        className="py-4 px-5"
      >
        {drawer === 1 ? (
          <FeedyardRank />
        ) : (
          <ComplianceCalculator form={form} open={!!drawer} />
        )}
      </Form>
    </Drawer>
  );
}

export default HeaderDrawer;
