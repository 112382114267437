/*
  Input Password
*/

import React from "react";

// Antd
import { Input, Form } from "antd";

// Translate
import { useTranslation } from "react-i18next";

// Style
import "./style.scss";

function TextArea({
  name,
  label,
  placeholder,
  defaultValue,
  onChange,
  disabled,
  required,
  onBlur,
  validateTrigger,
  validator,
  notwrapInForm,
  maxLength,
  minLength,
  rows,
  message,
  pattern,
  patternMessage,

}) {
  const { t } = useTranslation();
  const { TextArea } = Input;

  // Rules
  let rules = [
    { required: required, message: message ? message : t("Required") },
    { pattern: pattern, message: patternMessage },
    ...(validator || []),
  ];

  // Input Email
  const Text = (
    <TextArea
      placeholder={placeholder || label}
      onChange={onChange || null}
      defaultValue={defaultValue}
      disabled={disabled}
      rows={rows}
      name={name}
      onBlur={onBlur || null}
      maxLength={maxLength || 25}
      minLength={minLength || 8}
    />
  );

  return !!notwrapInForm ? (
    <>{Text}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
      data-testid="form"
      className="text-editor"
    >
      {Text}
    </Form.Item>
  );
}

export default TextArea;
