import StorageService from "../services/StorageService";

export const LANGUAGE = {
  EN: 1,
};

export const DEFAULT_VERSION_NUMBER = "0.1.1";

export const API_TIMEOUT = 12000000;

export const CONTACT_SUPPORT = "mailto:info@partnername.com";

export const NOTIFICATION_DURATION = 5;

export const PROCESSING_AUDITS = "processing_audits";
export const PENDING_JOBS = "pending_jobs";

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "PROGRESSIVE_BEEF_ADMIN_ACCESS_TOKEN",
  REFRESH_TOKEN: "PROGRESSIVE_BEEF_ADMIN_REFRESH_TOKEN",
  USER_INFO: "PROGRESSIVE_BEEF_ADMIN_USER_INFO",
  PERMISSIONS: "PROGRESSIVE_BEEF_ADMIN_PERMISSIONS",
  OFFLINE_IMAGE: "offlineImage",
};

export const LISTING_DATA = {
  FIRST_PAGE: 1,
  PAGE_SIZE: 50,
  PAGE_SIZE_INFO_BLOCK: 100,
  PAGE_SIZE_MAX_SIZE: 9999,
};

export const SORT = {
  ASC: "ascend",
  DESC: "descend",
};

export const SEARCH_TYPING_INTERVAL = 700;

// export const USER_ROLES = {
//   PROGRAM_MANAGER: 1,
//   TRAINER: 2,
//   FEEDLOT_MANAGER: 3,
//   INTERNAL_AUDITOR: 4,
//   EXTERNAL_AUDITOR: 5,
//   REVIWER: 6,
// };

export const USER_ROLES = {
  PROGRAM_MANAGER: 1,
  TRAINER: 2,
  FEEDLOT_MANAGER: 3,
  SUPERVISOR: 4,
  WORKER: 5,
  INTERNAL_AUDITOR: 6,
  EXTERNAL_AUDITOR: 7,
  INTERNAL_REVIEWER: 8,
  EXTERNAL_REVIEWER: 9,
};

export const LANGUAGE_KEY = {
  EN: 1,
  SP: 2,
};

export const FILE_TYPES = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  XLS: "application/vnd.ms-excel",
  PDF: "Content-type:application/pdf",
};

export const FILE_NAMES = {
  ADD_BULK_USER: "bulk_add_users_template",
  ADD_BULK_FEEDYARD_USER: "bulk_add_feedyard_users_template",
  Add_Bulk_User_Error_Report: "add_bulk_user_error_report",
  AUDIT_SUMMARY: "audit-summary",
};

export const SUBMISSION_STATUS = {
  ARCHIEVED: 1,
  PUBLISH: 2,
  DRAFT: 3,
  BIN: 5,
};

export const FEEDYARD_MODE = {
  TRAINING: 0,
  LIVE: 1,
};