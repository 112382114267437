/* 
  Header Drawer
*/

import React from "react";

// Redux
import {
  useDispatch
} from 'react-redux';

// Antd
import { Button } from "antd";

// Constant
import { API_URLS } from '../../../../constants/ApiConstants';
import { REDUX_STATES } from '../../../../constants/ReduxStates';

// Helpers
import { errorNotification } from '../../../../helpers/Notification';

// Actions
import { getList } from '../../../../store/actions/CRUDAction';

// Translate
import { useTranslation } from 'react-i18next';

const { FEEDYARD_RANK_LIST } = REDUX_STATES;

function FeedyardRankButton({
  setDrawer 
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onFeedyardRank = () => {
    dispatch(
      getList(
        API_URLS.FEEDYARD_RANK_LIST,
        {},
        FEEDYARD_RANK_LIST
      )
    ).then(() => {
      setDrawer(1)
    }, () => {
      errorNotification(t("errorFeedyardRankList"));
    })
  }

  return (
    <Button type="primary" onClick={ onFeedyardRank }>
      { t("feedyardRank") }
    </Button>
  );
}

export default FeedyardRankButton;